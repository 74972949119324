<template>
    <div id="wrapper">
        <navbar id="nav" class="animate__animated animate__fadeInLeft no-print"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn/>
                <div class="container-fluid">
                    <h3 class="text-dark multi-language"> {{ enginnerslanguage[language].enginners_page }} </h3>
                    <hr>
                    <div class="row" style="overflow-x: auto;">
                        <div class="mb-2 p-1 no-print"> 
                            <input type="text" class="form-control " v-bind:placeholder="enginnerslanguage[language].search + '...'" v-model="search">
                        </div>
                        <button class="btn btn-success mb-2 mx-1 no-print" v-on:click="get_actived()"> {{ enginnerslanguage[language].btn_actived }} </button>
                        <button class="btn btn-danger mb-2 mx-1 no-print" v-on:click="get_deactived()"> {{ enginnerslanguage[language].btn_deactived }} </button>
                        <button class="btn btn-primary mb-2 mx-1 no-print" onclick="window.print()"> Print <i class="fa fa-print"></i> </button>
                        <button class="btn btn-primary mb-2 mx-1 no-print" v-on:click="refresh()"> <i class="fa fa-redo"></i> </button>
                        <table class="table table-bordered table-striped text-dark text-center" >
                            <thead>
                                <th style="width: 8%;"> {{enginnerslanguage[language].ID}} </th>
                                <th> {{enginnerslanguage[language].first_name}}  </th>
                                <th> {{enginnerslanguage[language].last_name}} </th>
                                <th> {{enginnerslanguage[language].job_title}} </th>
                                <th style="width: 9%;"> {{enginnerslanguage[language].phone}} 1 </th>
                                <th style="width: 9%;"> {{enginnerslanguage[language].phone}} 2 </th>
                                <th style="width: 14%;"> {{enginnerslanguage[language].registration_date}} </th>
                                <th style="width: 7%;"> {{enginnerslanguage[language].status}} </th>
                                <th class="no-print"> {{enginnerslanguage[language].actions}} </th>
                            </thead>
                            <tbody>
                                <tr v-for="engineer in filtered_engineers" :key="engineer.en_id">
                                    <td> {{ engineer.en_id }} </td>
                                    <td> {{ engineer.first_name }} </td>
                                    <td> {{ engineer.last_name }} </td>
                                    <td> {{ engineer.job_title }} </td>
                                    <td> {{ engineer.phone1 }} </td>
                                    <td> {{ engineer.phone2 }} </td>
                                    <td> {{ engineer.reg_date | date_filter }} </td>
                                    <td v-bind:class="{ 'text-success': engineer.active_status == 1, 'text-danger': engineer.active_status == 0 }"> 
                                        {{ engineer.active_status | status_filter(language) }} 
                                    </td>
                                    <td class="no-print"> <button class="btn btn-primary" v-on:click="en_id = engineer.en_id" data-toggle="modal" data-target="#mdl_staff"> {{enginnerslanguage[language].see_staff}} </button> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modals -->
        <div class="modal animate__animated animate__fadeInDown animate__faster" id="mdl_staff">
            <div class="modal-dialog">
                <div class="modal-content text-dark">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2 multi-language"> {{enginnerslanguage[language].staffs}} </h5>
                    </div>
                    <div class="modal-body">
                        <table class="table table-bordered table-striped text-center">
                            <thead>
                                <th> {{enginnerslanguage[language].staff}} </th>
                            </thead>
                            <tbody>
                                <tr v-for="staff in filtered_staffs" :key="staff.st_id">
                                    <td> {{ staff.staff_name }} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import auth from '../../auth';
    import $ from 'jquery'
    import enginnerslanguage from '../../languages/engineers.js'
    import { Bus } from '../../Bus.js'

    export default {
        name: "staffReport",
        data() {
            return {
                engineers: [],
                search: "",
                staffs: [],
                en_id: null,
                enginnerslanguage,
                language:'English'
            }
        },
        beforeCreate() {
            auth("engineerReport");
        },
        created() {
            this.$http.post("/engineer/getEngineerReport").then(({data}) => {
                this.engineers = data;
            });
            this.$http.post("/staff/getData").then(({data}) => {
                this.staffs = data;
            });
        },
        mounted(){
            Bus.$on('languageChanged',(data)=>{
            this.language = data;
              if(["Kurdish", "Arabic"].includes(data)){
                 $(".multi-language").addClass("rtl");
                 $("#wrapper").attr("dir", "rtl")
              } else {
                 $(".multi-language").removeClass("rtl");
                 $("#wrapper").attr("dir", "ltr")
                }
            })
        },
        methods: {
            get_actived() {
                this.$http.post("/engineer/getActived").then(({data}) => {
                    this.engineers = data;
                });
            },
            get_deactived() {
                this.$http.post("/engineer/getDeactived").then(({data}) => {
                    this.engineers = data;
                });
            },
            refresh() {
                this.$http.post("/engineer/getEngineerReport").then(({data}) => {
                    this.engineers = data;
                });
            }
        },
        computed: {
            filtered_engineers() {
                return this.engineers.filter(obj => {
                    return obj.en_id == this.search ||
                           obj.first_name.match(this.search) ||
                           obj.last_name.match(this.search) ||
                           obj.job_title.match(this.search) ||
                           obj.phone1.match(this.search) ||
                           obj.phone2.match(this.search) ||
                           obj.reg_date.match(this.search);
                });
            },
            filtered_staffs() {
                return this.staffs.filter(obj => obj.en_id == this.en_id);
            }
        },
        filters: {
            status_filter(value,language) {
                return [
                    enginnerslanguage[language].deactive,
                    enginnerslanguage[language].active
                ][value];
            },
            date_filter(value) {
                var d = new Date(value);
                return d.toISOString().split("T")[0];
            }
        }
    }
</script>

<style scoped>
    @font-face {
        font-family: nrt;
        src: url("../../assets/fonts/NRT-Reg.ttf");
    }
    table, h3, h5 {
        font-family: nrt;
    }
    @media print {
        .no-print {
            display: none !important;
        }
    }
    .rtl {
    text-align: right !important;
    }
</style>