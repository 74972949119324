export default {
  Kurdish: {
    daily_staff_list_and_employee_attendance:"لیستی ڕۆژانەی ستافەکان و غیاباتی کارمەندان",
    list_info: "زانیاری لیست",
    list_id: "کۆدی لیست",
    work_date: "بەروار",
    staff: "ستاف",
    location: "شوێن",
    note: "تێبینی",
    add_list: "زیادکردنی لیست",
    save_changes: "گۆڕینی زانیاری",
    clear: "سڕینەوە",
    employee: "کارمەند",
    overtime: "کاتی زیادە",
    worked_hours: "کاتی ئیشکردن",
    status: "دۆخ",
    actions: "کردارەکان",
    absent: "نەهاتوو",
    present: "ئامادەبوو",
    off: "پشوو",
    fine_information: "زانیاری سەرپێچی",
    fine: "بڕی سەرپێچی",
    fine_reason: "هۆکاری سەرپێچی",
    save: "خەزن کردن",
    send_employee_to_another_staff: "ناردنی کارمەند بۆ ستافێکی دیکە",
    new_staff: "ستافی تازە",
    send: "ناردن",
    foods: "خواردنەکان",
    missed_employees: "کارمەندە داخڵ نەکراوەکان"
  },
  English: {
    daily_staff_list_and_employee_attendance:"Daily staff list and employee attendance",
    list_info: "List Info",
    list_id: "List ID",
    work_date: "Work Date",
    staff: "Staff",
    location: "Location",
    note: "Note",
    add_list: "Add List",
    save_changes: "Save Changes",
    clear: "Clear",
    employee: "Employee",
    overtime: "Over Time",
    worked_hours: "Worked(h)",
    status: "Status",
    actions: "Actions",
    absent: "Absent",
    present: "Present",
    off: "Off",
    fine_information: "Fine Information",
    fine: "Fine",
    fine_reason: "Fine reason",
    save: "Save",
    send_employee_to_another_staff: "Send employee to another staff",
    new_staff: "New Staff",
    send: "Send",
    foods: "Foods",
    missed_employees: "Missed employees"
  },
  Arabic: {
    daily_staff_list_and_employee_attendance:"القائمة اليومية لغياب الموظفين",
    list_info: "معلومات القائمة",
    list_id: "رقم القائمة",
    work_date: "تاريخ",
    staff: "العاملين",
    location: "موقع",
    note: "ملاحظة",
    add_list: "اضـف قائمة",
    save_changes: "تغيير المعلومات",
    clear: "حذف",
    employee: "موظـف",
    overtime: "وقت إضافي",
    worked_hours: "وقت العمل",
    status: "حالة",
    actions: "أجراءات",
    absent: "غـیاب",
    present: "كان حاضرا",
    off: "يوم الاجازة",
    fine_information: "معلومات الغرامة",
    fine: "مقدار الغرامة",
    fine_reason: "سبب الغرامة",
    save: "حفظ",
    send_employee_to_another_staff: "إرسال الموظفين إلى قائمة آخر",
    new_staff: "قائمة جديد",
    send: "ارسال",
    foods: "طعام",
    missed_employees: "موظفين غير مدمجين"
  }
};
