<template>
    <div id="wrapper">
        <navbar id="nav" class="no-print animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn class="no-print"/>
                <div class="container-fluid text-dark">
                    <div class="row px-1 py-3 mb-1 no-print">
                        <label class="mt-1 col-xl-1 mb-2"> Month: </label>
                        <select class="form-control col-xl-1 col-md-4 mb-2" v-model="month">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <label class="mt-1 col-xl-1 mb-2"> Year: </label>
                        <input type="number" min="2020" class="form-control col-xl-1 col-md-4 mb-2" v-model="year">

                        <label class="col-sm-1 mt-1"> Type: </label>
                        <select v-model="type" class="form-control col-sm-3 mb-2">
                            <option value="">  </option>
                            <option value="fine"> Fine </option>
                            <option value="food"> Food </option>
                            <option value="transport"> Transport </option>
                            <option value="expense"> Expense </option>
                            <option value="loan"> Loan </option>
                            <option value="accomodation"> Accomodation </option>
                        </select>

                        <button class="btn btn-success col-xl-2 col-md-4 mx-2 mb-2" v-on:click="search()"> Search <i class="fa fa-search"></i> </button><hr>
                    </div>
                    <hr class="no-print">
                    <button class="no-print btn btn-info btn-block mb-3" data-toggle="collapse" data-target="#staffs"> Staffs <i class="fa fa-arrow-down"></i> </button>
                    <div class="collapse" id="staffs">
                        <div class="row no-print">
                            <label class="col-sm-1 mt-1"> Staffs: </label>
                            <select v-model="staff_id" class="form-control col-sm-3 mb-2">
                                <option value=""></option>
                                <option v-for="staff in staffs.filter(obj => obj.special_staff == showSpecialStaffs)" :key="staff.st_id" v-bind:value="staff.st_id"> {{ staff.staff_name }} </option>
                            </select>
                            <span class="col-1">
                                <v-checkbox class="mt-1 custom-checkbox-label" v-model="showSpecialStaffs" true-value="true" false-value="false" label="Specials"></v-checkbox>
                            </span>
                            <label class="col-sm-1 mt-2"> Employee: </label>
                            <select v-model="emp_id" class="form-control col-sm-3 mb-2">
                                <option value=""></option>
                                <option v-for="emp in employees" :key="emp.emp_id" v-bind:value="emp.emp_id"> {{ emp.full_name }} </option>
                            </select>
                        </div>
                    </div>

                    <div class="row py-3 mb-1 no-print" dir="rtl" v-if="!user.en_id">
                        <label class="mt-2 col-xl-1 mb-1"> بەڕێوبەر </label>
                        <input class="form-control col-xl-2 col-md-4 mb-2" v-model="manager" type="text" @change="changeNames()">

                        <label class="mt-2 col-xl-1 mb-1"> ب.دارایی </label>
                        <input class="form-control col-xl-2 col-md-4 mb-2" v-model="kargeri" type="text" @change="changeNames()">
    
                        <label class="mt-2 col-xl-1 mb-1"> ژمێریار </label>
                        <input class="form-control col-xl-2 col-md-4 mb-2" v-model="accountant" type="text" @change="changeNames()">
                    </div>

                    <div>
                        <img src="../assets/Ideal power logo.png" style="height: 90px; width: 183px;">
                        <span class="text-center" style="display: inline-block; width: 68%; font-size: 36px">
                            Ideal Power Co.
                        </span>
                    </div>
                    
                    <hr class="my-0">
                    <div class="mt-3" style="color: black;">
                        <span class="float-start"> Staff: {{ staff_id ? staffs.find(obj => obj.st_id == staff_id).staff_name : '' }} </span>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <span class="ml-15"> Employee: {{ emp_id ? employees.find(obj => obj.emp_id == emp_id).full_name : '' }} </span>
                        <span class="float-end"> {{ type ? type.charAt(0).toUpperCase() + type.slice(1) : '' }} List For {{ month }}/{{year}} </span>
                    </div>
                    <br>

                    <table v-if="type == 'fine'" class="table table-bordered table-striped text-center text-dark" style="page-break-inside: always; font-size: 12pt !important;">
                        <thead>
                            <th style="width: 10%;"> List ID </th>
                            <th style="width: 12%;"> Work Date </th>
                            <th style="width: 12%;"> Fine </th>
                            <th> Fine Reason </th>
                        </thead>
                        <tbody>
                            <tr v-for="item in rows.filter(obj => obj.fine != 0)" :key="item.dsl_id">
                                <td>
                                    {{ item.dsl_id }}
                                </td>
                                <td> 
                                    {{ new Date(item.work_date).toISOString().split('T')[0] }}    
                                </td>
                                <td>
                                    {{ (item.fine).toLocaleString() }} {{ (employees.find(obj => obj.emp_id == emp_id) || {salary_type: ''}).salary_type == 'Monthly' ? '$' : 'IQD.' }}
                                </td>
                                <td>
                                    {{ item.fine_reason }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="para">
                                    Total
                                </td>
                                <td class="para">
                                    {{ total.toLocaleString() }} {{ (employees.find(obj => obj.emp_id == emp_id) || {salary_type: ''}).salary_type == 'Monthly' ? '$' : 'IQD.' }}
                                </td>
                                <td class="para">
                                    &nbsp;
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table v-else-if="type == 'transport'" class="table table-bordered table-striped text-center text-dark" style="page-break-inside: always; font-size: 12pt !important;">
                        <thead>
                            <th style="width: 10%;"> List ID </th>
                            <th style="width: 12%;"> Work Date </th>
                            <th style="width: 12%;"> Transport </th>
                            <th> Transport Reason </th>
                        </thead>
                        <tbody>
                            <tr v-for="item in rows.filter(obj => obj.transport != 0)" :key="item.dsl_id">
                                <td>
                                    {{ item.dsl_id }}
                                </td>
                                <td> 
                                    {{ new Date(item.work_date).toISOString().split('T')[0] }}    
                                </td>
                                <td>
                                    {{ (item.transport).toLocaleString() }} {{ (employees.find(obj => obj.emp_id == emp_id) || {salary_type: ''}).salary_type == 'Monthly' ? '$' : 'IQD.' }}
                                </td>
                                <td>
                                    {{ item.transport_reason }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="para">
                                    Total
                                </td>
                                <td class="para">
                                    {{ total.toLocaleString() }} {{ (employees.find(obj => obj.emp_id == emp_id) || {salary_type: ''}).salary_type == 'Monthly' ? '$' : 'IQD.' }}
                                </td>
                                <td class="para">
                                    &nbsp;
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table v-else-if="type == 'food'" class="table table-bordered table-striped text-center text-dark" style="page-break-inside: always; font-size: 12pt !important;">
                        <thead>
                            <th style="width: 10%;"> List ID </th>
                            <th style="width: 12%;"> Work Date </th>
                            <th style="width: 12%;"> Food </th>
                            <th> Food Reason </th>
                        </thead>
                        <tbody>
                            <tr v-for="item in rows.filter(obj => obj.food != 0)" :key="item.dsl_id">
                                <td>
                                    {{ item.dsl_id }}
                                </td>
                                <td> 
                                    {{ new Date(item.work_date).toISOString().split('T')[0] }}    
                                </td>
                                <td>
                                    {{ (item.food).toLocaleString() }} {{ (employees.find(obj => obj.emp_id == emp_id) || {salary_type: ''}).salary_type == 'Monthly' ? '$' : 'IQD.' }}
                                </td>
                                <td>
                                    {{ item.food_reason }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="para">
                                    Total
                                </td>
                                <td class="para">
                                    {{ total.toLocaleString() }} {{ (employees.find(obj => obj.emp_id == emp_id) || {salary_type: ''}).salary_type == 'Monthly' ? '$' : 'IQD.' }}
                                </td>
                                <td class="para">
                                    &nbsp;
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table v-else-if="type == 'expense'" class="table table-bordered table-striped text-center text-dark" style="page-break-inside: always; font-size: 12pt !important;">
                        <thead>
                            <th style="width: 10%;"> List ID </th>
                            <th style="width: 12%;"> Work Date </th>
                            <th style="width: 12%;"> Expense </th>
                            <th> Expense Reason </th>
                        </thead>
                        <tbody>
                            <tr v-for="item in rows.filter(obj => obj.expense != 0)" :key="item.dsl_id">
                                <td>
                                    {{ item.dsl_id }}
                                </td>
                                <td> 
                                    {{ new Date(item.work_date).toISOString().split('T')[0] }}    
                                </td>
                                <td>
                                    {{ (item.expense).toLocaleString() }} {{ (employees.find(obj => obj.emp_id == emp_id) || {salary_type: ''}).salary_type == 'Monthly' ? '$' : 'IQD.' }}
                                </td>
                                <td>
                                    {{ item.expense_reason }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="para">
                                    Total
                                </td>
                                <td class="para">
                                    {{ total.toLocaleString() }} {{ (employees.find(obj => obj.emp_id == emp_id) || {salary_type: ''}).salary_type == 'Monthly' ? '$' : 'IQD.' }}
                                </td>
                                <td class="para">
                                    &nbsp;
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table v-else-if="type == 'loan'" class="table table-bordered table-striped text-center text-dark" style="page-break-inside: always; font-size: 12pt !important;">
                        <thead>
                            <th style="width: 10%;"> List ID </th>
                            <th style="width: 12%;"> Work Date </th>
                            <th style="width: 12%;"> Loan </th>
                            <th> Loan Reason </th>
                        </thead>
                        <tbody>
                            <tr v-for="item in rows.filter(obj => obj.loan != 0)" :key="item.dsl_id">
                                <td>
                                    {{ item.dsl_id }}
                                </td>
                                <td> 
                                    {{ new Date(item.work_date).toISOString().split('T')[0] }}    
                                </td>
                                <td>
                                    {{ (item.loan).toLocaleString() }} {{ (employees.find(obj => obj.emp_id == emp_id) || {salary_type: ''}).salary_type == 'Monthly' ? '$' : 'IQD.' }}
                                </td>
                                <td>
                                    {{ item.loan_reason }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="para">
                                    Total
                                </td>
                                <td class="para">
                                    {{ total.toLocaleString() }} {{ (employees.find(obj => obj.emp_id == emp_id) || {salary_type: ''}).salary_type == 'Monthly' ? '$' : 'IQD.' }}
                                </td>
                                <td class="para">
                                    &nbsp;
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table v-else-if="type == 'accomodation'" class="table table-bordered table-striped text-center text-dark" style="page-break-inside: always; font-size: 12pt !important;">
                        <thead>
                            <th style="width: 10%;"> List ID </th>
                            <th style="width: 12%;"> Work Date </th>
                            <th style="width: 12%;"> Accomodation </th>
                            <th> Accomodation Reason </th>
                        </thead>
                        <tbody>
                            <tr v-for="item in rows.filter(obj => obj.accomodation != 0)" :key="item.dsl_id">
                                <td>
                                    {{ item.dsl_id }}
                                </td>
                                <td> 
                                    {{ new Date(item.work_date).toISOString().split('T')[0] }}    
                                </td>
                                <td>
                                    {{ (item.accomodation).toLocaleString() }} {{ (employees.find(obj => obj.emp_id == emp_id) || {salary_type: ''}).salary_type == 'Monthly' ? '$' : 'IQD.' }}
                                </td>
                                <td>
                                    {{ item.accomodation_reason }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="para">
                                    Total
                                </td>
                                <td class="para">
                                    {{ total.toLocaleString() }} {{ (employees.find(obj => obj.emp_id == emp_id) || {salary_type: ''}).salary_type == 'Monthly' ? '$' : 'IQD.' }}
                                </td>
                                <td class="para">
                                    &nbsp;
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <br><br>
                    <div class="row" style="color: black;">
                        <div class="col-4 justify-content-center text-center">
                            <span>
                                بەڕێوبەر
                            </span>
                            <br>
                            <span> {{ manager }} </span>
                        </div>
                        <div class="col-4 justify-content-center text-center">
                            <span>
                                ب.دارایی
                            </span>
                            <br>
                            <span> {{ kargeri }} </span>
                        </div>
                        <div class="col-4 justify-content-center text-center">
                            <span>
                                ژمێریار
                            </span>
                            <br>
                            <span> {{ accountant }} </span>
                            <br>
                            <span> {{ new Date().toISOString().split('T')[0] }} </span>
                            <br>
                            <span> {{ currentTime }} </span>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth';
export default {
    name: 'EmployeeMonthDetail',
    data() {
        return {
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
            staffs: [],
            staff_id: null,
            employees: [],
            emp_id: null,
            user: {
                username: '',
                en_id: '',
                role: ''
            },
            type: null,
            rows: [],
            manager: '',
            kargeri: '',
            accountant: '',
            currentTime: new Date().toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}),
            showSpecialStaffs: "false"
        }
    },
    beforeCreate(){
        auth("employee_month_detail");
    },
    created() {
        this.$http.get('/getNames').then(({data}) => {
            this.manager = data.manager;
            this.kargeri = data.kargeri;
            this.accountant = data.accountant;
        });
        this.$http.post("/staff/getData").then(({data}) => {
            this.staffs = data.filter(obj => obj.show_staff == '1').sort((a, b) => a.staff_sort_code - b.staff_sort_code)
        });
    },
    mounted() {
        setInterval(() => {
            this.currentTime = new Date().toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
        }, 1 * 60 * 1000);
    },
    methods: {
        search() {
            if(this.month && this.year && this.emp_id) {
                this.$http.get("/employee/getEmployeeMonthDetail/"  + this.month + '/' + this.year + '/' + this.emp_id).then(({data}) => {
                    this.rows = data;
                });
            } else {
                this.rows = [];
            }
        },
        changeNames() {
            this.$http.post('/setNames', {
                manager: this.manager,
                kargeri: this.kargeri,
                accountant: this.accountant
            }).then(() => {
                
            })
        }
    },
    computed: {
        total() {
            if(this.type == 'fine') {
                return this.rows.reduce((a, b) => a + b.fine, 0);
            } else if(this.type == 'food') {
                return this.rows.reduce((a, b) => a + b.food, 0);
            } else if(this.type == 'expense') {
                return this.rows.reduce((a, b) => a + b.expense, 0);
            } else if(this.type == 'loan') {
                return this.rows.reduce((a, b) => a + b.loan, 0);
            } else if(this.type == 'accomodation') {
                return this.rows.reduce((a, b) => a + b.accomodation, 0);
            } else if(this.type == 'transport') {
                return this.rows.reduce((a, b) => a + b.transport, 0);
            } else {
                return 0
            }
        }
    },
    watch: {
        staff_id(value) {
            this.employees = [];
            this.$data.emp_id = null
            if(value){
                this.$http.post("/employee/getEmployeeBystaff/" + this.staff_id + '/' + this.month + '/' + this.year).then(({data}) => {
                    this.employees = data;
                });
            } else {
                this.employees = [];
            }
        },
        emp_id(value) {
            if(value) {
                this.search();
            } else {
                this.rows = []
            }
        }
    }
}
</script>

<style scoped>
    .para {
        background-color: rgb(81, 224, 81) !important;
        color: black !important;
    }
    th, td {
        font-size: 11pt;
        /* border: 1px solid black !important; */
    }
    th, td:not(.text-danger) {
        color: rgb(17, 17, 17);
    }
    @media print {
        .no-print, #nav {
            display: none;
        }
        *:not(.para, .para-total, .expired-passport-date) {
            background-color: white ;
            
        }
        .para {
            background-color: rgb(81, 224, 81) !important;
            color: black !important;
        }
        .para-total {
            background-color: rgb(68, 171, 231) !important;
            color: black !important;
        }
        table {
            font-size: 9pt !important;
        }
        @page {
            size: landscape;
        }
    }
</style>