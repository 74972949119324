export default {
  Kurdish: {
    gived_employee_list: "لـیـستی مووچەی وەرگیراوی کارمەندان بۆ مانگی",
    month: "مـانـگ",
    year: "سـاڵ",
    search: "گـەڕان",
    id: "کـۆد",
    name: "نـاو",
    total_fine: "کۆی بڕی سەرپێچی",
    total_overtime: "کۆی بڕی کاتی زیادە",
    gived_amount: "بڕی مووچەی وەرگیراو",
    total_dollar: "کۆی بڕی دۆلار",
    total_dinar: "کۆی بڕی دینار",
    staff: "نـاوی سـتاف",
  },
  English: {
    gived_employee_list: "Employee gived salary list for month",
    month: "Month",
    year: "Year",
    search: "Search",
    id: "ID",
    name: "Name",
    total_fine: "Total fine",
    total_overtime: "Total overtime",
    gived_amount: "Gived amount",
    total_dollar: "Total dollar",
    total_dinar: "Total dinar",
    staff: "Staff",
  },
  Arabic: {
    gived_employee_list: "قائمة الرواتب التي يتقاضاها الموظفون عن الشهر",
    month: "شـهـر",
    year: "عـام",
    search: "بـحـث",
    id: "رقـم",
    name: "اسـم",
    total_fine: "المبلغ الإجمالي غرامة",
    total_overtime: "إجمالي الوقت الإضافي",
    gived_amount: "مبلغ الراتب المستلم",
    total_dollar: "المبلغ الإجمالي بالدولار",
    total_dinar: "المبلغ الإجمالي بالدينار",
    staff: "اسم العاملین",
  },
};