<template>
    <div id="wrapper">
        <navbar id="nav" class="animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn/>
                <div class="container-fluid">
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 class="text-dark mb-0" > {{ userLanguage[language].users }} </h3>
                    </div>
                    <hr>
                    <div class="row">
                        <button class="btn btn-success col-xl-2 col-md-5 mx-2 mb-2" data-toggle="modal" data-target="#mdl_user" > {{ userLanguage[language].add_user }} &nbsp; <i class="fa fa-plus"></i> </button>
                        <input type="text" class="form-control col-xl-2 col-md-5 mx-2 mb-2" v-bind:placeholder="userLanguage[language].search + '...'" v-model="user_search">
                        <span class="col-1">
                            <v-checkbox class="mt-0 custom-checkbox-label" v-model="activeStatus" true-value="1" false-value="0" label="Actives"></v-checkbox>
                        </span>
                    </div>
                    <div class="row overflow-auto">
                        <table class="table table-bordered table-striped text-center text-dark">
                            <thead>
                                <th style="width: 7%;"> {{ userLanguage[language].id }} </th>
                                <th> {{ userLanguage[language].username }} </th>
                                <th> {{ userLanguage[language].full_name }} </th>
                                <th style="width: 10%;"> {{ userLanguage[language].role }} </th>
                                <th style="width: 12%;"> {{ userLanguage[language].phone }} </th>
                                <th style="width: 8%;"> {{ userLanguage[language].status }} </th>
                                <th style="width: 16%;"> {{ userLanguage[language].actions }} </th>
                            </thead>
                            <tbody>
                                <tr v-for="user in filtered_users" :key="user.user_id">
                                    <td> {{ user.user_id }} </td>
                                    <td> {{ user.username }} </td>
                                    <td> {{ user.full_name }} </td>
                                    <td> {{ user.role | role_filter }} </td>
                                    <td> {{ user.phone }} </td>
                                    <td v-bind:class="{ 'text-success': user.active_status == '1', 'text-danger': user.active_status == '0' }"> {{ user.active_status | status_filter(language) }} </td>
                                    <td> 
                                        <button class="btn btn-primary mx-1" v-on:click="get_user(user.user_id)" data-toggle="modal" data-target="#mdl_user"> <i class="fa fa-edit"></i> </button>
                                        <button class="btn btn-danger mx-1" v-if="user.active_status == '1'" v-on:click="deactive_user(user.user_id)"> <i class="fa fa-lock"></i> </button>
                                        <button class="btn btn-success mx-1" v-if="user.active_status == '0'" v-on:click="active_user(user.user_id)"> <i class="fa fa-unlock"></i> </button>
                                        <button class="btn btn-danger mx-1" v-if="['U', 'A'].includes(user.role)" v-on:click="delete_user(user.user_id)"> <i class="fa fa-trash"></i> </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modals -->
        <change-pass/>
        
        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_user">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" v-on:click="clear_user()" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2"> User Information </h5>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row mb-3">
                                <label class="col-sm-3 mt-2"> {{ userLanguage[language].role }}: </label>
                                <select class=" form-control col-sm-9" v-model="single_user.role">
                                    <option value="A" v-if="single_user.user_id == null || ['U', 'A'].includes(single_user.role)">Admin</option>
                                    <option value="U" v-if="single_user.user_id == null || ['U', 'A'].includes(single_user.role)">User</option>
                                    <option value="E" v-if="single_user.user_id == null">Supervisor</option>
                                </select>
                            </div>
                            <div class="row mb-3">
                                <label class="col-sm-3 mt-2"> {{ userLanguage[language].first_name }}: </label>
                                <input type="text" class="form-control col-sm-9" v-model="single_user.first_name">
                            </div>
                            <div class="row mb-3">
                                <label class="col-sm-3 mt-2"> {{ userLanguage[language].last_name }}: </label>
                                <input type="text" class="form-control col-sm-9" v-model="single_user.last_name">
                            </div>
                            <div class="row mb-3">
                                <label class="col-sm-3 mt-2"> {{ userLanguage[language].password }}: </label>
                                <input type="text" class="form-control col-sm-9" v-model="single_user.password">
                            </div>
                            <div class="row mb-3">
                                <label class="col-sm-3 mt-2"> {{ userLanguage[language].phone }} 1: </label>
                                <input type="text" maxlength="11" class="form-control col-sm-9" v-model="single_user.phone1">
                            </div>
                            <div class="row mb-3" v-if="single_user.role == 'E' && single_user.user_id == null">
                                <label class="col-sm-3 mt-2"> {{ userLanguage[language].phone }} 2: </label>
                                <input type="text" maxlength="11" class="form-control col-sm-9" v-model="single_user.phone2">
                            </div>
                            <div class="row mb-3"  v-if="single_user.role == 'E' && single_user.user_id == null">
                                <label class="col-sm-3 mt-2"> {{ userLanguage[language].job_title }}: </label>
                                <select class="form-control col-sm-9" v-model="single_user.job_id">
                                    <option value=""></option>
                                    <option v-for="job in jobs" :key="job.job_id" v-bind:value="job.job_id"> {{ job.job_title }} </option>
                                </select>
                            </div>
                            <hr>
                            <div class="row mb-3">
                                <div class="col-sm-12 mb-2" v-if="single_user.user_id == null"> <button class="btn btn-success btn-block" id="btn_add_user" v-on:click="add_user()"> {{ userLanguage[language].add }} &nbsp; <i class="fa fa-plus"></i> </button> </div>
                                <div class="col-sm-6 mb-2" v-if="single_user.user_id != null"> <button class="btn btn-warning btn-block" v-on:click="update_user()"> {{ userLanguage[language].save }} &nbsp; <i class="fa fa-save"></i> </button> </div>
                                <div class="col-sm-6 mb-2" v-if="single_user.user_id != null"> <button class="btn btn-primary btn-block" v-on:click="update_password()"> {{ userLanguage[language].update_password }} &nbsp; <i class="fa fa-lock"></i> </button> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import auth from '../auth';
    import showError from '../showError'
    import $ from "jquery";
    import userLanguage from '../languages/user.js';
    import { Bus } from '../Bus.js';
    const nullValues = ["", null];
    export default {
        name: "Users",
        data() {
            return {
                users: [],
                single_user: {
                    user_id: null,
                    first_name: "",
                    last_name: "",
                    password: "",
                    phone1: "",
                    phone2: "",
                    role: "U",
                    job_id: null
                },
                jobs: [],
                user_search: "",
                userLanguage,
                language: "English",
                activeStatus: '1'
            }
        },
        beforeCreate() {
            auth("users");
        },
        created() {
            this.$http.post("/user/getData").then(({data}) => {
                this.users = data;
            });
            this.$http.post("/job/getData").then(({data}) => {
                this.jobs = data;
            });
        },
        mounted() {
            Bus.$on("languageChanged", (data) => {
                this.language = data;
                if(["Kurdish", "Arabic"].includes(data)){
                    $(".multi-language").addClass("rtl");
                    $("#wrapper").attr("dir", "rtl")
                } else {
                    $(".multi-language").removeClass("rtl");
                    $("#wrapper").attr("dir", "ltr")
                }
            });
        }, 
        methods: {
            get_user(user_id) {
                const [user] =  this.users.filter(obj => obj.user_id == user_id);
                this.single_user.user_id = user.user_id;
                this.single_user.first_name = user.full_name.split(" ")[0];
                this.single_user.last_name = user.full_name.split(" ")[1];
                this.single_user.phone1 = user.username;
                this.single_user.phone2 = user.phone;
                this.single_user.role = user.role;
            },
            clear_user() {
                this.single_user.user_id = null;
                this.single_user.first_name = "";
                this.single_user.last_name = "";
                this.single_user.password = "";
                this.single_user.phone1 = "";
                this.single_user.phone2 = "";
                this.single_user.role = "U";
                this.single_user.job_id = null;
            },
            async add_user() {
                if(this.single_user.role == "E"){
                    if(nullValues.includes(this.single_user.first_name)|| nullValues.includes(this.single_user.last_name) || nullValues.includes(this.single_user.phone1) || nullValues.includes(this.single_user.phone2) || nullValues.includes(this.single_user.job_id)){
                        this.$alert("Fill all required fields", "Empty Filed", "warning");
                    } else if(this.single_user.password.length < 6){
                        this.$alert("Minimum password character: 6", "Password", "warning");
                    } else {
                        document.getElementById("btn_add_user").setAttribute("disabled", "disabled");
                        this.$http.post("/engineer/addEngineer", {
                            first_name: this.single_user.first_name,
                            last_name: this.single_user.last_name,
                            job_id: this.single_user.job_id,
                            phone1: this.single_user.phone1,
                            phone2: this.single_user.phone2
                        }).then(({data}) => {
                            this.$http.post("/user/addUser", {
                                username: this.single_user.phone1,
                                password: this.single_user.password,
                                en_id: data.en_id,
                                full_name: this.single_user.first_name + ' ' + this.single_user.last_name,
                                role: this.single_user.role,
                                phone: this.single_user.phone1
                            }).then(({data}) => {
                                this.$alert("Successfully user added", "Success", "success");
                                this.users.unshift({
                                    user_id: data.user_id,
                                    username: this.single_user.phone1,
                                    full_name: this.single_user.first_name + ' ' + this.single_user.last_name,
                                    role: this.single_user.role,
                                    phone: this.single_user.phone1,
                                    active_status: "1"
                                });
                                this.clear_user();
                            });
                        }).catch((err) => {
                            showError(err);
                        }).finally(() => {
                            document.getElementById("btn_add_user").removeAttribute("disabled");
                        });
                    }
                } else {
                    if(nullValues.includes(this.single_user.phone1) || nullValues.includes(this.single_user.first_name) || nullValues.includes(this.single_user.last_name)){
                        this.$alert("Fill all required fields", "Empty Filed", "warning");
                    } else if(this.single_user.password.length < 6){
                        this.$alert("Minimum password character: 6", "Password", "warning");
                    } else {
                        this.$http.post("/user/addUser", {
                            username: this.single_user.phone1,
                            password: this.single_user.password,
                            full_name: this.single_user.first_name + ' ' + this.single_user.last_name,
                            role: this.single_user.role,
                            phone: this.single_user.phone1
                        }).then(({data}) => {
                            this.$alert("Successfully user added", "Success", "success");
                            this.users.push({
                                user_id: data.user_id,
                                username: this.single_user.phone1,
                                full_name: this.single_user.first_name + ' ' + this.single_user.last_name,
                                role: this.single_user.role,
                                phone: this.single_user.phone1,
                                active_status: "1"
                            });
                            this.clear_user();
                        }).catch((err) => {
                            showError(err);
                        });
                    }
                }
            },
            update_user() {
                if(nullValues.includes(this.single_user.phone1) || nullValues.includes(this.single_user.first_name) || nullValues.includes(this.single_user.last_name)){
                    this.$alert("Fill all required fields", "Empty Filed", "warning");
                } else {
                    this.$confirm("Do you want update the informations ?", "", "question").then(() => {
                        this.$http.patch("/user/updateUser/" + this.single_user.user_id, {
                            username: this.single_user.phone1,
                            full_name: this.single_user.first_name + ' ' + this.single_user.last_name,
                            role: this.single_user.role,
                            phone: this.single_user.phone1
                        }).then(() => {
                            let index = this.users.findIndex(obj => obj.user_id == this.single_user.user_id);
                            this.users[index].username = this.single_user.phone1;
                            this.users[index].full_name = this.single_user.first_name + ' ' + this.single_user.last_name;
                            this.users[index].role = this.single_user.role;
                            this.users[index].phone = this.single_user.phone1;
                            this.$alert("Informations successfully updated", "Success", "success");
                        }).catch((err) => {
                            showError(err);
                        });
                    });
                }
            },
            update_password() {
                if(this.single_user.password.length >= 6){
                    this.$confirm("Are you sure update password for this user account ?", "", "question").then(() => {
                        this.$http.patch("/user/updatePassword/" + this.single_user.user_id, {
                            password: this.single_user.password
                        }).then(() => {
                            this.$alert("Password successfully updated", "Success", "success");
                            this.single_user.password = "";
                        });
                    });
                } else {
                    this.$alert("Minimum password character: 6", "Password", "warning");
                }
            },
            deactive_user(user_id) {
                this.$confirm("Are you sure deactive this user account ?", "", "question").then(() => {
                    this.$http.patch("/user/deactiveUser/" + user_id).then(() => {
                        let index = this.users.findIndex(obj => obj.user_id == user_id);
                        this.users[index].active_status = "0";
                        this.$alert("User successfully deactived", "Success", "success");
                    }).catch((err) => {
                        showError(err);
                    });
                });
            },
            active_user(user_id) {
                this.$confirm("Are you sure active this user account ?", "", "question").then(() => {
                    this.$http.patch("/user/activeUser/" + user_id).then(() => {
                        let index = this.users.findIndex(obj => obj.user_id == user_id);
                        this.users[index].active_status = "1";
                        this.$alert("User successfully actived", "Success", "success");
                    }).catch((err) => {
                        showError(err);
                    });
                });
            },
            delete_user(user_id) {
                this.$confirm("Are you sure delete this user account ?", "", "question").then(() => {
                    this.$http.delete("/user/deleteUser/" + user_id).then(() => {
                        this.$alert("User successfully deleted", "Success", "success");
                        this.users = this.users.filter(obj => obj.user_id != user_id);
                    }).catch((err) => {
                        showError(err);
                    });
                });
            }
        },
        computed: {
            filtered_users() {
                return this.users.filter(obj => {
                    return (obj.user_id == this.user_search || 
                           obj.username.match(this.user_search) ||
                           obj.full_name.match(this.user_search) ||
                           obj.phone.match(this.user_search) ||
                           obj.role.toLowerCase().match(this.user_search)) && 
                           obj.active_status == this.activeStatus;
                });
            }
        },
        filters: {
            status_filter(value, language) {
                return [
                    userLanguage[language].deactive,
                    userLanguage[language].active
                ][value];
            },
            role_filter(value) {
                if(value == "A"){
                    return "Admin";
                } else if(value == "U"){
                    return "User";
                }
                return "Engineer";
            }
        }
    }
</script>

<style scoped>
    .rtl {
        text-align: right !important;
    }
</style>