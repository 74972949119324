<template>
    <div id="wrapper">
        <navbar id="nav" class="animate__animated animate__fadeInLeft no-print"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn/>
                <div class="container-fluid text-dark">
                    <h3 class="text-dark multi-language"> {{ topEmployeeLanguage[language].employees_data }} {{ month + '/' + year }}    </h3>
                    <hr>
                    <div class="row " style="overflow-x: auto;">
                        <label class="col-sm-1 mt-1 no-print mb-1"> {{ topEmployeeLanguage[language].month }} : </label>
                        <select class="form-control col-sm-1 no-print mb-1" v-model="month">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>

                        <label class="col-sm-1 mt-1 no-print mb-1"> {{ topEmployeeLanguage[language].year }} : </label>
                        <input type="number" class="form-control col-sm-1 no-print mb-1" v-model="year">

                        <button class="btn btn-success mx-3 no-print" v-on:click="search()"> {{ topEmployeeLanguage[language].search }} <i class="fa fa-search"></i> </button>                        
                    </div>
                    <div class="row " style="overflow-x: auto;">

                        <div class="col-xl-6 col-md-12 col-sm-12 float-right">
                            <label class="float-right my-3 alert alert-success w-100 multi-language"> {{ topEmployeeLanguage[language].overtime }} : </label>
                            <table class="table table-bordered table-striped text-dark text-center mt-2">
                                <thead> 
                                    <th style="width: 20%"> {{ topEmployeeLanguage[language].id }} </th>
                                    <th style="width: 50%"> {{ topEmployeeLanguage[language].name }} </th>
                                    <th style="width: 30%"> {{ topEmployeeLanguage[language].amount_overtime }} </th>
                                </thead>
                                <tbody>
                                    <tr v-for="employee in top_overtime" :key="employee.emp_id">
                                        <td> {{ employee.emp_id }} </td>
                                        <td> {{ employee.full_name }} </td>
                                        <td> {{ employee.total_o }} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-xl-6 col-md-12 col-sm-12 float-right">
                            <label class="float-right my-3 alert alert-danger w-100 multi-language"> {{ topEmployeeLanguage[language].absent }} : </label>
                            <table class="table table-bordered table-striped text-dark text-center mt-2">
                                <thead> 
                                    <th style="width: 20%"> {{ topEmployeeLanguage[language].id }} </th>
                                    <th style="width: 50%"> {{ topEmployeeLanguage[language].name }} </th>
                                    <th style="width: 30%"> {{ topEmployeeLanguage[language].amount_absent }} </th>
                                </thead>
                                <tbody>
                                    <tr v-for="employee in top_absent" :key="employee.emp_id">
                                        <td> {{ employee.emp_id }} </td>
                                        <td> {{ employee.full_name }} </td>
                                        <td> {{ employee.total_apsent }} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-xl-6 col-md-12 col-sm-12 float-right">
                            <label class="float-right my-3 alert alert-primary w-100 multi-language"> {{ topEmployeeLanguage[language].fine }} : </label>
                            <table class="table table-bordered table-striped text-dark text-center mt-2">
                                <thead> 
                                    <th style="width: 20%"> {{ topEmployeeLanguage[language].id }} </th>
                                    <th style="width: 50%"> {{ topEmployeeLanguage[language].name }} </th>
                                    <th style="width: 30%"> {{ topEmployeeLanguage[language].amount_fine }} (IQD) </th>
                                </thead>
                                <tbody>
                                    <tr v-for="employee in top_fine" :key="employee.emp_id">
                                        <td> {{ employee.emp_id }} </td>
                                        <td> {{ employee.full_name }} </td>
                                        <td> {{ employee.total_f }} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import auth from '../../auth';
    import $ from 'jquery';
    import { Bus } from '../../Bus.js';
    import topEmployeeLanguage from '../../languages/topEmployees.js';
    export default {
        name: "topEmployees",
        data() {
            return {
                month: new Date().getMonth(),
                year: new Date().getFullYear(),
                top_overtime: [],
                top_absent: [],
                top_fine: [],
                topEmployeeLanguage,
                language: "English"
            }
        },
        beforeCreate() {
            auth("topEmployeeReport");
        },
        created() {
            this.search();
        },
        mounted() {
            Bus.$on("languageChanged", (data) => {
                this.language = data;
                if(["Kurdish", "Arabic"].includes(data)){
                    $(".multi-language").addClass("rtl");
                    $("#wrapper").attr("dir", "rtl")
                } else {
                    $(".multi-language").removeClass("rtl");
                    $("#wrapper").attr("dir", "ltr")
                }
            });
        },
        methods: {
            search() {
                this.$http.post("/employee/topOvertime/" + this.month + "/" + this.year).then(({data}) => {
                    this.top_overtime = data.top_overtime;
                    this.top_absent = data.top_absent;
                    this.top_fine = data.top_fine;
                });
            }
        },
        filters: {
            money_filter(value) {
                var num = value.toString().split("").reverse();
                var arr = [];
                for (let i = 0; i < num.length; i++) {
                    arr.unshift(num[i]);
                    if((i + 1) % 3 == 0){
                        arr.unshift(",");
                    }
                }
                var num_with_comma = arr.join("");
                if(num_with_comma.charAt(0) == ','){
                    return num_with_comma.slice(1);
                }
                return num_with_comma;
            },
            fine_filter(value) {
                if(value == "0.00"){
                    return 0;
                }
                return value;
            }
        },
        watch: {
            year() {
                this.search();
            },
            month() {
                this.search();
            }
        }
    }
</script>

<style scoped>
    .rtl {
        text-align: right !important;
    }
    td {
        padding: .5rem;
        direction: ltr;
    }
    @media print {
        .no-print{
            display: none !important;
        }
    }
</style>