<template>
    <div id="wrapper">
        <navbar id="nav" class="no-print animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn class="no-print"/>
                <div class="container-fluid text-dark">
                    <div class="row px-1 py-3 mb-1 no-print">
                        <label class="mt-1 col-xl-1 mb-2"> Month: </label>
                        <select class="form-control col-xl-1 col-md-4 mb-2" v-model="month">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <label class="mt-1 col-xl-1 mb-2"> Year: </label>
                        <input type="number" min="2020" class="form-control col-xl-1 col-md-4 mb-2" v-model="year">

                        <label class="col-sm-1 mt-1" v-if="isSaved"> Groups: </label>
                        <select v-model="group" class="form-control col-sm-3 mb-2" v-if="isSaved">
                            <option value=""></option>
                            <option value="barzayakan"> بەرزاییەکان </option>
                            <option value="A"> A </option>
                            <option value="B"> B </option>
                            <option value="C"> C </option>
                            <option value="D"> D </option>
                            <option value="E"> E </option>
                        </select>
                        
                        <button class="btn btn-success col-xl-2 col-md-4 mx-2 mb-2" v-on:click="search()"> Search <i class="fa fa-search"></i> </button><hr>
                    </div>
                    <div class="row px-1 py-3 mb-1 no-print">
                        <label class="mt-2 col-xl-2 mb-1"> Price Barzayakan </label>
                        <input class="form-control col-xl-1 col-md-4 mb-2" :disabled="isSaved" v-model.number="price_barzayakan" type="number">
                        
                        <label class="mt-2 col-xl-1 mb-1"> Price A </label>
                        <input class="form-control col-xl-1 col-md-4 mb-2" :disabled="isSaved" v-model.number="price_A" type="number">

                        <label class="mt-2 col-xl-1 mb-1"> Price B </label>
                        <input class="form-control col-xl-1 col-md-4 mb-2" :disabled="isSaved" v-model.number="price_B" type="number">

                        <label class="mt-2 col-xl-1 mb-1"> Price C </label>
                        <input class="form-control col-xl-1 col-md-4 mb-2" :disabled="isSaved" v-model.number="price_C" type="number">

                        <label class="mt-2 col-xl-1 mb-1"> Price D </label>
                        <input class="form-control col-xl-1 col-md-4 mb-2" :disabled="isSaved" v-model.number="price_D" type="number">
                        
                        <label class="mt-2 col-xl-2 mb-1"> Price E </label>
                        <input class="form-control col-xl-1 col-md-4 mb-2" :disabled="isSaved" v-model.number="price_E" type="number">

                        <template v-if="!group">
                            <button class="btn btn-primary col-xl-1 col-md-1 mx-2 mb-2" v-if="!isSaved" v-on:click="add_list()" id="btn-add"> Add <i class="fa fa-plus"></i> </button>
                            <button class="btn btn-danger col-xl-1 col-md-1 mx-2 mb-2" v-else v-on:click="delete_list()" id="btn-delete"> Delete <i class="fa fa-trash"></i> </button>
                        </template>
                    </div>
                    
                    <hr class="no-print">
                    
                    <div>
                        <img src="../assets/Ideal power logo.png" style="height: 90px; width: 180px;">
                        <span class="text-center" style="display: inline-block; width: 70%; font-size: 34px">
                            Ideal Power Co.
                        </span>
                    </div>
                    
                    <hr class="my-0">
                    <div class="mt-3" style="color: black;">
                        <span class="float-start"> Food List For {{ month }}/{{year}} </span>
                        <span class="float-end" v-if="staff_id"> Food List for Staff: {{ staff_id ? staffs.find(obj => obj.st_id == staff_id).staff_name : '' }} </span>
                    </div>
                    <br>
                    <div class="table-responsive mt-5">

                        <table class="table table-bordered table-striped text-center text-dark">
                            <thead>
                                <th> Staff </th>
                                <th> Food Group </th>
                                <th> Food Numbers </th>
                                <th> Price </th>
                                <th> Total </th>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in filtered_data" :key="index">
                                    <td> {{ item.staff_name }} </td>
                                    <td> {{ item.food_group | food_group_filter }} </td>
                                    <td> {{ item.food_number }} </td>
                                    <td >
                                        {{
                                            (item.food_group == 'barzayakan' ? price_barzayakan : item.food_group == 'A' ? price_A : item.food_group == 'B' ? price_B : item.food_group == 'C' ? price_C : item.food_group == 'D' ? price_D : item.food_group == 'E' ? price_E : 0).toLocaleString()
                                        }}
                                    </td>
                                    <td>
                                        {{
                                            (item.food_group == 'barzayakan' ? item.food_number * price_barzayakan :item.food_group == 'A' ? item.food_number * price_A : item.food_group == 'B' ? item.food_number * price_B : item.food_group == 'C' ? item.food_number * price_C : item.food_group == 'D' ? item.food_number * price_D : item.food_group == 'E' ? item.food_number * price_E : 0).toLocaleString()
                                        }}
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="4" class="para"> Total </td>
                                    <td class="para"> 
                                        {{
                                            filtered_data.reduce((a, b) => a + (b.food_group == 'barzayakan' ? b.food_number * price_barzayakan : b.food_group == 'A' ? b.food_number * price_A : b.food_group == 'B' ? b.food_number * price_B : b.food_group == 'C' ? b.food_number * price_C : b.food_group == 'D' ? b.food_number * price_D : b.food_group == 'E' ? b.food_number * price_E : 0), 0).toLocaleString()
                                        }}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth';
export default {
    name: 'FoodList',
    data() {
        return {
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
            foodList: [],
            price_barzayakan: 0,
            price_A: 0,
            price_B: 0,
            price_C: 0,
            price_D: 0,
            price_E: 0,
            isSaved: false,
            staffs: [],
            staff_id: null,
            group: null,
        }
    },
    beforeCreate(){
        auth("foodList");
    },
    created(){
        this.$http.post("/staff/getData").then(({data}) => {
            this.staffs = data.filter(obj => obj.show_staff == '1');
        });
    },
   
    methods: {
        search() {
            this.$http.get('/daily_staff_list/getFoodList/' + this.month + '/' + this.year).then(({data}) => {
                this.foodList = data.data;
                this.isSaved = data.message
                if(this.foodList.length > 0) {
                    this.price_barzayakan = this.foodList.find(obj => obj.food_group == 'barzayakan') ? this.foodList.find(obj => obj.food_group == 'barzayakan').price_barzayakan : 0;
                    this.price_A = this.foodList.find(obj => obj.food_group == 'A') ? this.foodList.find(obj => obj.food_group == 'A').price_A : 0;
                    this.price_B = this.foodList.find(obj => obj.food_group == 'B') ? this.foodList.find(obj => obj.food_group == 'B').price_B : 0;
                    this.price_C = this.foodList.find(obj => obj.food_group == 'C') ? this.foodList.find(obj => obj.food_group == 'C').price_C : 0;
                    this.price_D = this.foodList.find(obj => obj.food_group == 'D') ? this.foodList.find(obj => obj.food_group == 'D').price_D : 0;
                    this.price_E = this.foodList.find(obj => obj.food_group == 'E') ? this.foodList.find(obj => obj.food_group == 'E').price_E : 0;
                }
            })
        },
        add_list() {
            if(this.foodList.length == 0) return alert('No data to add');

            this.group = null;
            const c = confirm('Are you sure you want to add this list?');
            if(c) {
                document.getElementById('btn-add').setAttribute('disabled', 'disabled');
                const arr = this.foodList.map(item => {
                    return {
                        dsl_id: item.dsl_id,
                        food_group: item.food_group,
                        food_number: item.food_number,
                        price_barzayakan: this.price_barzayakan,
                        price_A: this.price_A,
                        price_B: this.price_B,
                        price_C: this.price_C,
                        price_D: this.price_D,
                        price_E: this.price_E,
                        month: this.month,
                        year: this.year
                    }
                })
                this.$http.post('/daily_staff_list/saveFoodList', {
                    list: arr
                }).then(() => {
                    alert('List added successfully');
                    document.getElementById('btn-add').removeAttribute('disabled');
                    this.foodList = []
                    this.isSaved = false
                }).finally(() => {
                    document.getElementById('btn-add').removeAttribute('disabled');
                })
            }
        },
        delete_list() {
            if(this.foodList.length == 0) return alert('No data to delete');

            this.group = null;
            const c = confirm('Are you sure you want to delete this list?');
            if(c) {
                document.getElementById('btn-delete').setAttribute('disabled', 'disabled');
                this.$http.delete('/daily_staff_list/deleteFoodList/' + this.month + '/' + this.year).then(() => {
                    alert('List deleted successfully');
                    document.getElementById('btn-delete').removeAttribute('disabled');
                    this.isSaved = false
                }).finally(() => {
                    document.getElementById('btn-delete').removeAttribute('disabled');
                })
            }
        }
    },
    computed: {
        filtered_data () {
            if(this.group) {
                return this.foodList.filter(obj => obj.food_group == this.group)
            } else {
                return this.foodList
            }
        }
    },
    watch: {
        month() {
            this.search();
        },
        year() {
            this.search();
        }
    },
    filters:{ 
        food_group_filter(value) {
            return {
                A: 'A',
                B: 'B',
                C: 'C',
                D: 'D',
                E: 'E',
                barzayakan: 'بەرزاییەکان'
            }[value]
        }
    }
}
</script>

<style scoped>
    .rtl {
        text-align: right !important;
    }
    tbody td {
        padding: .5rem;
    }
    .para {
        background-color: rgb(81, 224, 81) !important;
        color: black !important;
    }
    .para-total {
        background-color: rgb(68, 171, 231) !important;
        color: black !important;
    }
    @media print {
        .no-print, #nav {
            display: none;
        }
        *:not(.para, .para-total, .expired-passport-date) {
            background-color: white ;
            
        }
        .para {
            background-color: rgb(81, 224, 81) !important;
            color: black !important;
        }
        .para-total {
            background-color: rgb(68, 171, 231) !important;
            color: black !important;
        }
        table {
            font-size: 9pt !important;
        }
        @page {
            size: landscape;
        }
    }
</style>