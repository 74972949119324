export default {
  Kurdish: {
    employees_data:"داتای کارمەندان بۆ : ( غیابات - کاتی زیادە - سەرپێچی ) لە مانگی",
    month: "مانگ",
    year: "سـاڵ",
    search: "گـەڕان",
    overtime: "کاتی زیادە",
    absent: "ئامادە نەبوون",
    fine: "بڕی سەرپێچی",
    id: "کۆد",
    name: "نـاو",
    amount_overtime: "کاتی زیادە",
    amount_absent: "ڕۆژی نەهاتوو",
    amount_fine: "سەرپێچی"
  },
  English: {
    employees_data: "Employee's data for: (Absents, Overtime, Fine) in month",
    month: "Month",
    year: "Year",
    search: "Search",
    overtime: "Overtime",
    absent: "Absent",
    fine: "Fine",
    id: "ID",
    name: "Name",
    amount_overtime: "Overtime",
    amount_absent: "Absent days",
    amount_fine: "Fine"
  },
  Arabic: {
    employees_data:"بيانات الموظف عن: (الغياب - العمل الإضافي - المخالفة) شهرياً",
    month: "شـهر",
    year: "عـام",
    search: "بـحـث",
    overtime: "وقت إضافي",
    absent: "غیابات",
    fine: "مقدار الغرامة",
    id: "رقـم",
    name: "اسـم",
    amount_overtime: "وقت إضافي",
    amount_absent: "یوم الغیاب",
    amount_fine: "الغرامة"
  }
};
