export default {
 Kurdish : {
    enginners_page: "سەرپەرشتیارەکان",
    btn_deactive:"سەرپەرشتیارە ناچالاکەکان",
    search:"گەڕان",
    ID:"کۆد",
    first_name:"ناوی یەکەم",
    last_name:"ناوی دووەم",
    job_title:"جۆری کار",
    phone:"ژ.مۆبایل",
    registration_date:"بەرواری تۆمارکردن",
    status:"دۆخ",
    actions:"کردارەکان",
    deactive:"ناچالاک",
    active:"چالاک",
    en_info:"زانیاری سەرپەرشتیارەکان",
    btn_save:"گۆڕینی زانیاری",
    btn_delete:"سڕینەوە",
    deactive_list:"لیستی سەرپەرشتیارە ناچالاکەکان",
    btn_deactived:'ناچالاک کراوەکان',
    btn_actived:'چالاک کراوەکان',
    see_staff:"بینینی ستافەکان",
    staffs:'ستافەکان',
    staff:"ستاف"
 },
 English:{
  enginners_page: "Supervisors",
  btn_deactive:"Deactived Supervisors",
  search:"Search",
  ID:"ID",
  first_name:"First Name",
  last_name:"Last Name",
  job_title:"Job Title",
  phone:"phone",
  registration_date:"Registration Date",
  status:"Status",
  actions:"Actions",
  deactive:"Deactive",
  active:"Active",
  en_info:"Supervisors Information",
  btn_save:"Save Change",
  btn_delete:"Delete",
  deactive_list:"Deactived Supervisors List",
  btn_deactived:'Deactived',
  btn_actived:'Actived',
  see_staff:"See Staffs",
  staffs:'Staffs',
  staff:"Staff" 
 },
 Arabic: {
   enginners_page: "المشرفين",
   btn_deactive:"المشرفون غير النشطين",
   search:"بحث",
   ID:"رقـم",
   first_name:"الاسم الاول",
   last_name:"الاسم الثاني",
   job_title:"نوع العمل",
   phone:"موبایل",
   registration_date:"تاريخ التسجيل",
   status:"حالة",
   actions:"أجراءات",
   deactive:"معاق",
   active:"نشيط",
   en_info:"معلومات المشرفين",
   btn_save:"تغيير المعلومات",
   btn_delete:"حذف",
   deactive_list:"قائمة المشرفين غير النشطين",
   btn_deactived:'معاق',
   btn_actived:'منشط',
   see_staff:"انظر العاملين",
   staffs:'العاملين',
   staff:"العاملين"
 }
}