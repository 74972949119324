export default {
    Kurdish: {
      home: "سەرەکی",
      users: "بەکارهێنەرەکان",
      supervisors: "سەرپەرشتیارەکان",
      employees: "کارمەندەکان",
      attendance: "غیابات",
      give_salary: "پێدانی مووچە",
      reports: "ڕاپۆرتەکان",
      staffs: "ستافەکان",
      give_salary_report: "مووچە وەرگیراوەکان",
      top_employees: "داتای کارمەندان",
      staff_list_report: "لیستی کارکردنی ستافەکان",
      salary_list: "لیستی مووچە"
    },
    English: {
      home: "Home",
      users: "Users",
      supervisors: "Supervisors",
      employees: "Employees",
      attendance: "Attendance",
      give_salary: "Give Salary",
      reports: "Reports",
      staffs: "Staffs",
      give_salary_report: "Gived salary",
      top_employees: "Top Employees",
      staff_list_report: "Staff List reports",
      salary_list: "Salary List"
    },
    Arabic: {
      home: "الصفحة الرئيسية",
      users: "بەکارهێنەرەکان",
      supervisors: "المشرفين",
      employees: "الموظفين",
      attendance: "غیابات",
      give_salary: "كشف رواتب",
      reports: "التقارير",
      staffs: "العاملين",
      give_salary_report: "تم استلام الرواتب",
      top_employees: "بيانات الموظف",
      staff_list_report: "قائمة العاملین",
      salary_list: "قائمة رواتب"
    }
  };