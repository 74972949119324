<template>
    <div id="wrapper">
        <navbar id="nav" class="animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">

            <div id="content">
                <header-btn />
                <div class="container-fluid"> 
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 class="text-dark mb-0" > Notifications </h3>
                    </div>
                    <hr>
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                        <button class="btn btn-success" data-toggle="modal" data-target="#mdlNotification" @click="clearSingleNotification()"> <i class="fas fa-plus"></i>&nbsp; Add Notification </button>
                    </div>

                    <v-data-table :items-per-page="20" :footer-props="{'items-per-page-options': [15, 20, 25, 30]}" :headers="headers" :items="notifications">
                        <template v-slot:[`item.display_type`]="{item}">
                            {{ item.display_type | display_type_filter }}
                        </template>

                        <template v-slot:[`item.date_from`]="{item}">
                            {{ item.date_from ? new Date(item.date_from).toISOString().split('T')[0] : '' }}
                        </template>

                        <template v-slot:[`item.date_to`]="{item}">
                            {{ item.date_to ? new Date(item.date_to).toISOString().split('T')[0] : '' }}
                        </template>

                        <template v-slot:[`item.color`]="{item}">
                            <i class="fas fa-circle fa-2x" :style="`color: ${item.color}`"></i>
                        </template>

                        <template v-slot:[`item.show_notification`]="{item}">
                            <v-switch v-if="user.type == 'A'" v-model="item.show_notification" true-value="true" false-value="false" dense inset style="height: 30px; margin: 0;" @change="changeShowNotification(item.notification_id, item.show_notification)"></v-switch>
                        </template>

                        <template v-slot:[`item.actions`]="{item}">
                            <template v-if="user.type == 'A'">
                                <v-btn icon color="blue" data-toggle="modal" data-target="#mdlNotification" @click="getNotification(item.notification_id)"> <v-icon> mdi-pencil </v-icon> </v-btn>
                                <v-btn icon color="red" @click="deleteNotification(item.notification_id)"> <v-icon> mdi-delete </v-icon> </v-btn>
                            </template>
                        </template>

                    </v-data-table>
                </div>

            </div>
        </div>

        <!-- Modals -->
        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdlNotification">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal">&times;</button>
                        <h5 class="mt-2"> 
                            Notification 
                            <button class="btn btn-primary ms-2" v-if="!single_notification.notification_id" @click="addNotification()" :disabled="loading_notification"> <v-icon color="white">  mdi-plus </v-icon> </button> 
                            <button class="btn btn-warning ms-2" v-else @click="updateNotification()" :disabled="loading_notification"> <v-icon color="white">  mdi-content-save </v-icon> </button> 
                        </h5>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row mb-3">
                                <label class="col-1 mt-2"> Type: </label>
                                <select class="form-control col-lg-11 col-xl-11 col-sm-12 " v-model="single_notification.display_type"> 
                                    <option value="daily"> Daily </option>
                                    <option value="multi_day"> Multi Day </option>
                                    <option value="monthly"> Monthly </option>
                                    <option value="active_employee" v-if="user.type == 'A'"> Active Employee </option>
                                </select>
                            </div>
                            <div class="row mb-3" v-if="single_notification.display_type == 'daily'">
                                <label class="col-1 mt-2"> From: </label>
                                <input type="date" class="form-control col-lg-5 col-xl-5 col-sm-12 " v-model="single_notification.date_from">
                                <label class="col-1 mt-2"> To: </label>
                                <input type="date" class="form-control col-lg-5 col-xl-5 col-sm-12 " v-model="single_notification.date_to">
                            </div>

                            <div class="row mb-3" v-if="single_notification.display_type == 'multi_day'">
                                <v-checkbox style="width: 75px;" class="custom-checkbox-label" v-for="d in 31" :label="d" :value="d" :key="'d' + d" v-model="selected_days"></v-checkbox>
                            </div>

                            <div class="row mb-3" v-if="single_notification.display_type == 'monthly'">
                                <label class="col-1 mt-2"> Day: </label>
                                <input type="number" class="form-control col-lg-11 col-xl-11 col-sm-12 " v-model="single_notification.day">
                            </div>

                            <div class="row mb-3" v-if="single_notification.display_type == 'active_employee'">
                                <label class="col-1 mt-2"> Staff: </label>

                                <select class="form-control col-lg-5 col-xl-5 col-sm-12 " v-model="st_id"> 
                                    <option value=""> </option>
                                    <option v-for="staff in staffs" :key="'st' + staff.st_id" :value="staff.st_id"> {{ staff.staff_name }} </option>
                                </select>

                                <label class="col-2 mt-2"> Employee: </label>

                                <select class="form-control col-lg-4 col-xl-4 col-sm-12" v-model="single_notification.emp_id"> 
                                    <option value=""> </option>
                                    <option v-for="employee in employees" :key="'emp' + employee.emp_id" :value="employee.emp_id"> {{ employee.full_name }} </option>
                                </select>
                            </div>

                            <div class="row mb-3">
                                <label class="col-1 mt-2"> Color: </label> 
                                <input type="color" class=" col-lg-2 col-xl-2 col-sm-12 mt-1 " v-model="single_notification.color">
                            </div>

                            <div class="row mb-3">
                                <label class="col-1 mt-2"> Text: </label> 
                                <input class="form-control col-lg-11 col-xl-11 mt-1 col-sm-12" :dir="isEnglishText(single_notification.text) ? 'ltr' : 'rtl'" v-model="single_notification.text"/>
                                
                            </div>

                            <div class="row mb-3">
                                <label class="col-1 mt-2"> Users: </label>
                                <select class="form-control col-lg-5 col-xl-5 col-sm-12 " @change="addUser" v-model="user_id"> 
                                    <option value=""></option>
                                    <option value="all_users">All</option>
                                    <option v-for="user in users" :key="'user' + user.user_id" :value="user.user_id"> {{ user.full_name }} </option>
                                </select>
                                <label class="col-2 mt-2" v-if="user.type == 'A' || user.type == 'K'"> Special: </label>
                                <select v-if="user.type == 'A' || user.type == 'K'" class="form-control col-lg-4 col-xl-4 col-sm-12 " @change="addUser" v-model="user_id_eng"> 
                                    <option value=""></option>
                                    <option value="all_engineers">All</option>
                                    <option v-for="user in engineers" :key="'usere' + user.user_id" :value="user.user_id"> {{ user.full_name }} </option>
                                </select>
                            </div>

                            <div class="row mb-3">
                                <v-chip class="me-3 mt-2" v-for="u in selected_users" :key="'su' + u.user_id"> {{ u.full_name }} &nbsp; <span class="ms-2" style="font-size: 10pt; color: red; cursor: pointer;" @click="selected_users = selected_users.filter(obj => obj.user_id != u.user_id)"> <i class="fas fa-trash"></i> </span> </v-chip>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth';
export default {
    data() {
        return {
            user: {
                user_id: null,
                username: "",
                type: ""
            },
            notifications: [],
            single_notification: {
                display_type: "daily",
                date_from: null,
                date_to: null,
                day: null,
                emp_id: null,
                color: null,
                text: ''
            },
            st_id: null,
            user_id: null,
            user_id_eng: null,
            staffs: [],
            employees: [],
            users: [],
            engineers: [],
            selected_users: [],
            headers: [
                {
                    text: 'Type',
                    align: 'start',
                    sortable: false,
                    value: 'display_type'
                },
                { text: 'Date From', value: 'date_from' },
                { text: 'Date To', value: 'date_to' },
                { text: 'Day', value: 'day' },
                { text: 'Days', value: 'days' },
                { text: 'Employee', value: 'emp_name' },
                { text: 'Color', value: 'color', sortable: false },
                { text: 'Text', value: 'text' },
                { text: 'Username', value: 'username' },
                { text: 'Show', value: 'show_notification', sortable: false },
                { text: 'Actions', value: 'actions', align: 'center', sortable: false},
                
            ],
            loading_notification: false,
            selected_days: []
        }
    },
    beforeCreate() {
        auth("notifications");
    },
    created() {
        this.$http.post("/getLoggedInfo").then(({data}) => {
            this.user.username = data.username;
            this.user.type = data.type;
            this.user.user_id = data.user_id;

            if(this.user.type == 'A') {
                this.$http.post("/notifications/getData").then(({data}) => {
                    this.notifications = data
                })
                this.$http.post("/user/getActived").then(({data}) => {
                    this.users = data.users
                    this.engineers = data.engineers
                })

            } else {
                this.$http.post("/notifications/getDataByUser/" + this.user.user_id).then(({data}) => {
                    this.notifications = data
                })
                this.$http.post("/user/getSelf/" + this.user.user_id).then(({data}) => {
                    this.users = data
                })
            }

        })


        this.$http.post('/staff/getData').then(({data})=>{
            this.staffs = data.filter(obj => obj.show_staff == '1').sort((a, b) => a.staff_sort_code - b.staff_sort_code) 
        });
    },
    methods: {
        addUser(e) {
            if(e.target.value == 'all_engineers') {
                const all_engineers = this.engineers.filter(obj => !this.selected_users.find(obj2 => obj2.user_id == obj.user_id))
                this.selected_users.push(...all_engineers);
                this.user_id = null;
                this.user_id_eng = null;
                return
            }

            if(e.target.value == 'all_users') {
                const all_users = this.users.filter(obj => !this.selected_users.find(obj2 => obj2.user_id == obj.user_id))
                this.selected_users.push(...all_users);
                this.user_id = null;
                this.user_id_eng = null;
                return
            }

            const exist = this.selected_users.find(obj => obj.user_id == e.target.value);
            if(exist) {
                this.user_id = null;
                this.user_id_eng = null;
                return;
            }

            const finded = this.users.find(obj => obj.user_id == e.target.value) || this.engineers.find(obj => obj.user_id == e.target.value);
            this.selected_users.push(finded);
            this.user_id = null;
            this.user_id_eng = null;

        },
        clearSingleNotification() {
            this.single_notification = {
                display_type: "daily",
                date_from: null,
                date_to: null,
                day: null,
                emp_id: null,
                color: null,
                text: ''
            }
            this.selected_users = [];
            this.selected_days = [];
            this.st_id = null
        },
        addNotification() {
            if(this.selected_users.length == 0) {
                alert("Please select users")
                return
            }

            if(this.single_notification.display_type == 'multi_day' && this.selected_days.length == 0) {
                alert("Please select days")
                return
            }



            this.loading_notification = true;
            const notification = {
                display_type: this.single_notification.display_type,
                date_from: this.single_notification.display_type == 'daily' ? this.single_notification.date_from : null,
                date_to: this.single_notification.display_type == 'daily' ? this.single_notification.date_to : null,
                day: this.single_notification.display_type == 'monthly' ? this.single_notification.day : null,
                emp_id: this.single_notification.display_type == 'active_employee' ? this.single_notification.emp_id : null,
                days: this.single_notification.display_type == 'multi_day' ? this.selected_days.join(',') : null,
                color: this.single_notification.color,
                text: this.single_notification.text,
                username: this.user.username,
                user_id: this.user.user_id
            }

            this.$http.post('/notifications/addNotification', {
                notification: notification,
                selected_users: this.selected_users
            
            }).then(({data}) => {
                this.notifications.unshift(data.new_data);
                this.single_notification = {
                    display_type: "daily",
                    date_from: null,
                    date_to: null,
                    day: null,
                    emp_id: null,
                    color: null,
                    text: ''
                }
                this.selected_users = [];
                this.selected_days = [];
                this.loading_notification = false;
            });
        },
        getNotification(id) {
            this.$http.post('/notifications/getByID/' + id).then(({data}) => {
                let notification = data.notification

                this.st_id = notification.st_id

                this.single_notification = {
                    notification_id: notification.notification_id,
                    display_type: notification.display_type,
                    day: notification.day,
                    emp_id: notification.emp_id,
                    color: notification.color,
                    text: notification.text
                }

                if(notification.display_type == 'multi_day') {
                    this.selected_days = notification.days.split(',').map(obj => parseInt(obj));
                } else {
                    this.selected_days = [];
                }

                if(notification.date_from) {
                    this.single_notification.date_from = new Date(notification.date_from).toISOString().split('T')[0];
                } else {
                    this.single_notification.date_from = null;
                }

                if(notification.date_to) {
                    this.single_notification.date_to = new Date(notification.date_to).toISOString().split('T')[0];
                } else {
                    this.single_notification.date_to = null;
                }

                this.selected_users = data.users;
            })
        },
        updateNotification() {
            if(this.selected_users.length == 0) {
                alert("Please select users")
                return
            }

            if(this.single_notification.display_type == 'multi_day' && this.selected_days.length == 0) {
                alert("Please select days")
                return
            }


            this.loading_notification = true;
            const notification = {
                display_type: this.single_notification.display_type,
                date_from: this.single_notification.display_type == 'daily' ? this.single_notification.date_from : null,
                date_to: this.single_notification.display_type == 'daily' ? this.single_notification.date_to : null,
                day: this.single_notification.display_type == 'monthly' ? this.single_notification.day : null,
                emp_id: this.single_notification.display_type == 'active_employee' ? this.single_notification.emp_id : null,
                days: this.single_notification.display_type == 'multi_day' ? this.selected_days.join(',') : null,
                color: this.single_notification.color,
                text: this.single_notification.text
            }

            this.$http.patch('/notifications/updateNotification/' + this.single_notification.notification_id, {
                notification: notification,
                selected_users: this.selected_users
            }).then(() => {
                let index = this.notifications.findIndex(obj => obj.notification_id == this.single_notification.notification_id);
                this.notifications[index].display_type = notification.display_type;
                this.notifications[index].date_from = notification.date_from;
                this.notifications[index].date_to = notification.date_to;
                this.notifications[index].day = notification.day;
                this.notifications[index].days = notification.days
                this.notifications[index].emp_id = notification.emp_id;
                this.notifications[index].color = notification.color;
                this.notifications[index].text = notification.text;

                this.$alert("Notification updated successfully", "Success", "success");
            })
        },

        changeShowNotification(id, value) {
            
            this.$http.patch('/notifications/changeShow/' + id, {
                show_notification: value
            }).then(() => {

            })
            
        },
        deleteNotification(id) {
            this.$confirm('Do you want to delete this notification ?', "Delete Notification", "question").then(() => {
                this.$http.delete('/notifications/deleteNotification/' + id).then(() => {
                    this.notifications = this.notifications.filter(obj => obj.notification_id != id);
                });
            })
        },
        isEnglishText(text) {
            if(!text) {
                return true
            }
            // Regular expression to match English letters and digits
            var englishLettersAndDigitsRegex = /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/;
            
            // Test if the text contains only English letters and digits
            return englishLettersAndDigitsRegex.test(text);
        },
    },
    watch: {
        st_id() {
            this.$http.post('/employee/getDeactivedEmployeeBystaff/' + this.st_id).then(({data}) => {
                this.employees = data.sort((a, b) => a.sort_code - b.sort_code)
            });
        }
    },
    filters: {
        display_type_filter(value) {
            return {
                daily: "Daily",
                multi_day: "Multi Day",
                monthly: "Monthly",
                active_employee: "Active Employee"
            }[value]
        }
    }
}
</script>