import Vue from "vue";
import VueSimpleAlert from "vue-simple-alert";
import "animate.css";
import Axios from "axios";
import excel from "vue-excel-export";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

import navbar from "./components/navbar.vue";
import header_btn from "./components/headerBtn.vue";
import changePass from "./components/changePass.vue";
import vuetify from './plugins/vuetify'

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

Vue.use(VueViewer)

Vue.config.productionTip = false;

// Axios.defaults.baseURL = "http://localhost:3000";

Vue.prototype.$image_path = process.env.NODE_ENV == 'production' ? "https://idealpower.co" : "http://localhost:3000";

Axios.defaults.withCredentials = true;
Vue.prototype.$http = Axios;

Vue.use(VueSimpleAlert);
Vue.use(excel)

Vue.component("navbar", navbar);
Vue.component("header-btn", header_btn);
Vue.component("change-pass", changePass);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
