export default {
  Kurdish: {
    users: "بەکارهێنەرەکان",
    add_user: "زیادکردنی بەکارهێنەر",
    search: "گـەڕان",
    id: "کۆد",
    username: "ناوی بەکارهێنەر",
    full_name: "ناوی تەواو",
    role: "ڕۆڵ",
    phone: "ژ.مۆبایل",
    status: "دۆخ",
    actions: "کردارەکان",
    user_information: "زانیاری بەکارهێنەر",
    first_name: "ناوی یەکەم",
    last_name: "ناوی دووەم",
    password: "وشەی نهێنی",
    job_title: "کار",
    add: "زیادکردن",
    save: "گۆڕانکاری",
    update_password: "گۆڕینی وشەی نهێنی",
    active: "چالاک",
    deactive: "ناچالاک",
  },
  English: {
    users: "Users",
    add_user: "Add User",
    search: "Search",
    id: "ID",
    username: "Username",
    full_name: "Full name",
    role: "Role",
    phone: "Phone",
    status: "Status",
    actions: "Actions",
    user_information: "User Information",
    first_name: "First name",
    last_name: "Last name",
    password: "Password",
    job_title: "Job Title",
    add: "Add",
    save: "Save",
    update_password: "Update Password",
    active: "Active",
    deactive: "Deactive",
  },
  Arabic: {
    users: "المستخدمون",
    add_user: "إضافة المستخدمين",
    search: "بحث",
    id: "رقـم",
    username: "اسم المستخدم",
    full_name: "الاسم الكامل",
    role: "وظيفة",
    phone: "موبایل",
    status: "حالة",
    actions: "أجراءات",
    user_information: "معلومات المستخدم",
    first_name: "الاسم الاول",
    last_name: "الاسم الثاني",
    password: "كلمه السر",
    job_title: "عمل",
    add: "اضافة",
    save: "تغيير المعلومات",
    update_password: "تغیير كلمة السر",
    active: "نشيط",
    deactive: "معاق",
  },
};