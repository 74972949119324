<template>
    <nav class="navbar navbar-light navbar-expand bg-white shadow mb-4 topbar static-top">
        <div class="container-fluid"><button class="btn btn-link d-md-none rounded-circle mr-3" id="sidebarToggleTop" type="button"><i class="fas fa-bars"></i></button>
            <ul class="nav navbar-nav flex-nowrap w-100 ps-0">
                <!-- <div class="d-none d-sm-block topbar-divider"></div>
                <li class="nav-item mx-3">
                    <select class="form-control my-3" id="language" v-model="langauge">
                        <option value="English"> English </option>
                        <option value="Kurdish"> کوردی </option>
                        <option value="Arabic"> العربية </option>
                    </select>
                </li> -->
                
                <!-- <div class="d-none d-sm-block topbar-divider"></div>
                
                <li class="nav-item dropdown no-arrow">
                    <div class="nav-item dropdown no-arrow">
                        <a class=" nav-link px-0 position-relative" data-toggle="modal" data-target="#mdlNotify" href="#">
                            <i class="fas fa-bell" style="font-size: 18pt; color: #4e73df;"></i>
                            <span class="position-absolute " style="color: white; margin-top: -22px; margin-left: 15px; font-size: 12.5pt;">
                               <i class="fas fa-exclamation-circle" style="color: red;"></i>
                            </span>
                        </a>
                        
                    </div>
                </li> -->

                <div style="display: flex; justify-content: center; align-items: center;" class="w-100">
                    <marquee width="100%" direction="right" height="25px" v-if="notifications && notifications.length > 0"> 
                        <template v-for="notf in notifications">
                            <div :style="`display: inline; color: ${notf.color};`" :key="'ntf' + notf.notification_id">
                                <span dir="ltr"> {{ notf.username }}: {{ notf.text }} </span> 
                            </div>
                            <span class="mx-3" :key="'div-ntf' + notf.notification_id"> | </span> 
                        </template>
                        
                    </marquee>
                </div>

                <div class="d-none d-sm-block topbar-divider"></div>

                <li class="nav-item dropdown no-arrow">
                    <div class="nav-item dropdown no-arrow">
                        <a class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false" href="#">
                            <span class="d-none d-lg-inline mx-2 text-gray-600 small"> {{ username }} </span>
                            <img class="border rounded-circle img-profile" src="../assets/user.jpg">
                        </a>
                        <div class="dropdown-menu shadow dropdown-menu-right animated--grow-in"><a class="dropdown-item" href="#"></a>
                            <a class="dropdown-item" href="javascript:void(0)" role="button" data-toggle="modal" data-target="#mdl_change_pass"><i class="fa fa-key fa-sm fa-fw mr-2 text-gray-800"></i>&nbsp;Change Password</a>
                            <a class="dropdown-item" href="javascript:void(0)" v-on:click="logout()"><i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-800"></i>&nbsp;Logout</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    import { Bus } from "../Bus.js";
    
    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
    
    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    export default {
        props: ['notifications'],
        name: "header-btn",
        data() {
            return {
                username: "",
                langauge: "English"
            }
        },
        created() {
            this.$http.post("/getLoggedInfo").then(({data}) => {
                this.username = data.username;
            });

            if(["Kurdish", "English", "Arabic"].includes(getCookie("ideal_power_lang"))){
                this.langauge = getCookie("ideal_power_lang");
            } else {
                setCookie("ideal_power_lang", "English", 30);
            }
        },
        methods: {
            logout() {
                this.$http.post("/logout").then(() => {
                    window.location.href = '/login';
                });
            }
        },
        watch: {
            langauge(value) {
                setCookie("ideal_power_lang", value, 30);
                Bus.$emit("languageChanged", value);
            }
        }
    }
</script>