<template>
    <div id="wrapper">
        <navbar id="nav" class="no-print animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn class="no-print"/>
                <div class="container-fluid text-dark">
                    <div class="row px-1 py-3 mb-1 no-print">
                        <label class="mt-1 col-xl-1 mb-2"> Month: </label>
                        <select class="form-control col-xl-1 col-md-4 mb-2" v-model="month">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                        <label class="mt-1 col-xl-1 mb-2"> Year: </label>
                        <input type="number" min="2020" class="form-control col-xl-1 col-md-4 mb-2" v-model="year">

                        <label class="col-sm-1 mt-1"> Staffs: </label>
                        <select v-model="staff_id" class="form-control col-sm-3 mb-2">
                            <option value=""></option>
                            <option v-for="staff in staffs" :key="staff.st_id" v-bind:value="staff.st_id"> {{ staff.staff_name }} </option>
                        </select>
                        
                        
                        <button class="btn btn-success col-xl-2 col-md-4 mx-2 mb-2" v-on:click="search()"> Search <i class="fa fa-search"></i> </button><hr>
                    </div>
                    <hr class="no-print">
                    
                    <div>
                        <img src="../assets/Ideal power logo.png" style="height: 90px; width: 180px;">
                        <span class="text-center" style="display: inline-block; width: 70%; font-size: 34px">
                            Ideal Power Co.
                        </span>
                    </div>
                    
                    <hr class="my-0">
                    <div class="mt-3" style="color: black;">
                        <span class="float-start"> Food List For {{ month }}/{{year}} </span>
                        <span class="float-end" v-if="staff_id"> Food List for Staff: {{ staff_id ? staffs.find(obj => obj.st_id == staff_id).staff_name : '' }} </span>
                    </div>
                    <br>
                    <div class="table-responsive mt-5">

                        <table class="table table-bordered table-striped text-center text-dark">
                            <thead>
                                <th> List ID </th>
                                <th> Work Date </th>
                                <th> Food Number </th>
                                <th> Food Group </th>
                                <th> Food Date </th>
                            </thead>
                            <tbody>
                                <tr v-for="item in list" :key="item.dsl_id">
                                    <td> {{ item.dsl_id }} </td>
                                    <td> {{ new Date(item.work_date).toISOString().split('T')[0] }} </td>
                                    <td> {{ item.food_number }} </td>
                                    <td> {{ item.food_group }} </td>
                                    <td> 
                                        {{ item.datetime_food ? new Date(item.datetime_food).toISOString().split('T')[0] : '' }}
                                        {{ item.datetime_food ? new Date(item.datetime_food).toLocaleString([], {timeZone: 'America/Danmarkshavn', hour: '2-digit', minute: '2-digit'}) : '' }} 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth';
export default {
    name: "foodGroups",
    data() {
        return {
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
            staff_id: null,
            staffs: [],
            list: []
        }
    },
    beforeCreate(){
        auth("salaryList");
    },
    created(){
        this.$http.post("/staff/getData").then(({data}) => {
            this.staffs = data.filter(obj => obj.show_staff == '1');
        });
    },
    methods: {
        search(){
            if(this.month && this.year && this.staff_id) {
                this.$http.get('/daily_staff_list/getFoodsByStaff/' + this.month + '/' + this.year + '/' + this.staff_id).then(({data}) => {
                    this.list = data;
                })
            } else {
                this.list = []
            }
        }
    },
    watch: {
        month(){
            this.search();
        },
        year(){
            this.search();
        },
        staff_id(){
            this.search();
        }
    }
}
</script>

<style scoped>
    .rtl {
        text-align: right !important;
    }
    tbody td {
        padding: .5rem;
    }
    .para {
        background-color: rgb(81, 224, 81) !important;
        color: black !important;
    }
    .para-total {
        background-color: rgb(68, 171, 231) !important;
        color: black !important;
    }
    @media print {
        .no-print, #nav {
            display: none;
        }
        *:not(.para, .para-total, .expired-passport-date) {
            background-color: white ;
            
        }
        .para {
            background-color: rgb(81, 224, 81) !important;
            color: black !important;
        }
        .para-total {
            background-color: rgb(68, 171, 231) !important;
            color: black !important;
        }
        table {
            font-size: 9pt !important;
        }
        @page {
            size: landscape;
        }
    }
</style>