<template>
    <div class="modal" id="mdl_change_pass">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="btn btn-danger" data-dismiss="modal"> &times; </button>
                </div>
                <div class="modal-body">
                    <input type="text" v-if="user_type != 'K'" class="form-control text-center mb-3" placeholder="New Password" v-model="password">
                    <button v-if="user_type != 'K'" class="btn btn-primary btn-block" v-on:click="change_pass()" v-bind:disabled="password.length < 6"> <i class="fa fa-save"></i> &nbsp; Save </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "changePass", 
        data() {
            return {
                password: "",
                user_id: null,
                user_type: null
            }
        },
        created() {
            this.$http.post("/getLoggedInfo").then(({data}) => {
                this.user_id = data.user_id;
                this.user_type = data.type;
            });
        },
        methods: {
            change_pass() {
                if(this.password.length >= 6 && this.user_id != null){
                    this.$http.patch("/user/updatePassword/" + this.user_id, {
                        password: this.password
                    }).then(() => {
                        this.$alert("Success", "Success", "success");
                        this.password = "";
                    });
                } else {
                    this.$alert("Password must be more than 6 chatacter", "Minimum 6 character", "warning");
                }
            }
        }
    }
</script>