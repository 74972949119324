<template>
    <div id="wrapper">
        <navbar id="nav" class="animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn/>
                <div class="container-fluid">
                    <h3 class="text-dark mb-4 multi-language">{{ enginnerslanguage[language].enginners_page }}</h3>
                    <hr>
                    <div class="row">
                        <input type="text" class="form-control col-xl-3 col-md-5 mb-2" v-bind:placeholder="enginnerslanguage[language].search + '...'" v-model="search_engineer">
                        <button class="btn btn-danger float-right mb-2 mx-2" data-toggle="modal" data-target="#mdl_deactived_engineers"> {{ enginnerslanguage[language].btn_deactive }} </button>
                    </div>
                    <div class="row overflow-auto">
                        <table class="table table-bordered table-striped text-center text-dark">
                            <thead>
                                <th> {{enginnerslanguage[language].ID}} </th>
                                <th> {{enginnerslanguage[language].first_name}} </th>
                                <th> {{enginnerslanguage[language].last_name}} </th>
                                <th> {{enginnerslanguage[language].job_title}} </th>
                                <th style="width: 10%;"> {{enginnerslanguage[language].phone}} 1 </th>
                                <th style="width: 10%;"> {{enginnerslanguage[language].phone}} 2 </th>
                                <th style="width: 14%;"> {{enginnerslanguage[language].registration_date}} </th>
                                <th style="width: 7%;"> {{enginnerslanguage[language].status}} </th>
                                <th style="width: 12%;"> {{enginnerslanguage[language].actions}} </th>
                            </thead>
                            <tbody>
                                <tr v-for="engineer in filtered_engineers" :key="engineer.en_id">
                                    <td> {{ engineer.en_id }} </td>
                                    <td> {{ engineer.first_name }} </td>
                                    <td> {{ engineer.last_name }} </td>
                                    <td> {{ engineer.job_title }} </td>
                                    <td> {{ engineer.phone1 }} </td>
                                    <td> {{ engineer.phone2 }} </td>
                                    <td> {{ engineer.reg_date | date_filter }} </td>
                                    <td class="text-success"> {{ engineer.active_status | status_filter(language) }} </td>
                                    <td> 
                                        <button class="btn btn-primary mx-1" v-on:click="get_engineer(engineer.en_id)" data-toggle="modal" data-target="#mdl_engineer"> <i class="fa fa-edit"></i> </button>
                                        <button class="btn btn-danger mx-1" v-on:click="deactive_engineer(engineer.en_id)"> <i class="fa fa-lock"></i> </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modals -->
        <change-pass/>
        
        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_engineer">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" v-on:click="clear_engineer()" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2"> {{ enginnerslanguage[language].en_info }} </h5>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row mb-3">
                                <label class="col-sm-3 mt-1"> {{enginnerslanguage[language].first_name}}: </label>
                                <input type="text" class="form-control col-sm-9" placeholder="Enter first name" v-model="single_engineer.first_name">
                                
                            </div>
                            <div class="row mb-3">
                                <label class="col-sm-3 mt-1"> {{enginnerslanguage[language].first_name}}: </label>
                                <input type="text" class="form-control col-sm-9" placeholder="Enter last name" v-model="single_engineer.last_name">
                            </div>
                            <div class="row mb-3">
                                <label class="col-sm-3 mt-1"> {{enginnerslanguage[language].job_title}} : </label>
                                <select class="form-control col-sm-9" v-model="single_engineer.job_id">
                                    <option v-for="job in jobs" :key="job.job_id" v-bind:value="job.job_id"> {{ job.job_title }} </option>
                                </select>
                            </div>
                            <div class="row mb-3">
                                <label class="col-sm-3 mt-1"> {{enginnerslanguage[language].phone}} 1: </label>
                                <input type="text" class="form-control col-sm-9" placeholder="Enter first phone number" v-model="single_engineer.phone1">
                            </div>
                            <div class="row mb-3">
                                <label class="col-sm-3 mt-1"> {{enginnerslanguage[language].phone}} 2: </label>
                                <input type="text" class="form-control col-sm-9" placeholder="Enter second phone number" v-model="single_engineer.phone2">
                            </div>
                            <hr>
                            <div class="row mb-3">
                                <div class="col-xl-6 mb-1"> <button class="btn btn-warning btn-block" id="btn_update_engineer" v-on:click="update_engineer()"> {{enginnerslanguage[language].btn_save}} <i class="fa fa-save"></i> </button> </div>
                                <div class="col-xl-6 mb-1"> <button class="btn btn-danger btn-block" v-on:click="delete_engineer()"> {{enginnerslanguage[language].btn_delete}} <i class="fa fa-trash"></i> </button> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="mdl_deactived_engineers">
            <div class="modal-dialog modal-xl">
                <div class="modal-content text-dark">
                    <div class="modal-header">
                        <h5 class="mt-2"> {{ enginnerslanguage[language].deactive_list }} </h5>
                        <button class="btn btn-danger" data-dismiss="modal"> &times; </button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row overflow-auto">
                                <input type="text" class="form-control col-xl-3 col-md-5 mb-2" v-bind:placeholder="enginnerslanguage[language].search + '...'" v-model="search_deactive_engineer">
                                <table class="table table-bordered table-striped text-center text-dark">
                                    <thead>
                                        <th> {{enginnerslanguage[language].ID}} </th>
                                        <th> {{enginnerslanguage[language].first_name}} </th>
                                        <th> {{enginnerslanguage[language].last_name}} </th>
                                        <th> {{enginnerslanguage[language].job_title}} </th>
                                        <th style="width: 10%;"> {{enginnerslanguage[language].phone}} 1 </th>
                                        <th style="width: 10%;"> {{enginnerslanguage[language].phone}} 2 </th>
                                        <th style="width: 15%;"> {{enginnerslanguage[language].registration_date}} </th>
                                        <th style="width: 7%;"> {{enginnerslanguage[language].status}} </th>
                                        <th style="width: 5%;"> {{enginnerslanguage[language].actions}} </th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="engineer in filtered_deactived_engineers" :key="engineer.en_id">
                                            <td> {{ engineer.en_id }} </td>
                                            <td> {{ engineer.first_name }} </td>
                                            <td> {{ engineer.last_name }} </td>
                                            <td> {{ engineer.job_title }} </td>
                                            <td> {{ engineer.phone1 }} </td>
                                            <td> {{ engineer.phone2 }} </td>
                                            <td> {{ engineer.reg_date.slice(0, 10) }} </td>
                                            <td class="text-danger"> {{ engineer.active_status | status_filter(language) }} </td>
                                            <td> 
                                                <button class="btn btn-success mx-1" v-on:click="active_engineer(engineer.en_id)"> <i class="fa fa-unlock"></i> </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import auth from '../auth';
    import showError from '../showError';
    import $ from 'jquery'
    import enginnerslanguage from '../languages/engineers.js'
    import { Bus } from '../Bus.js'

    const nullValues = ["", null];
    export default {
        name: "Engineers",
        data() {
            return {
                jobs: [],
                engineers: [],
                single_engineer: {
                    en_id: null,
                    first_name: "",
                    last_name: "",
                    job_id: null,
                    phone1: "",
                    phone2: "",
                },
                deactived_engineers: [],
                search_engineer: "",
                search_deactive_engineer: "",
                enginnerslanguage,
                language: "English"
            }
        },
        beforeCreate() {
            auth("engineers");
        },
        created() {
            this.$http.post("/job/getData").then(({data}) => {
                this.jobs = data;
            });
            this.$http.post("/engineer/getData").then(({data}) => {
                this.engineers = data;
            });
            this.$http.post("/engineer/getDeactived").then(({data}) => {
                this.deactived_engineers = data;
            });

        },
        mounted(){
           Bus.$on('languageChanged',(data)=>{
            this.language = data;
              if(["Kurdish", "Arabic"].includes(data)){
                 $(".multi-language").addClass("rtl");
                 $("#wrapper").attr("dir", "rtl")
              } else {
                 $(".multi-language").removeClass("rtl");
                 $("#wrapper").attr("dir", "ltr")
                }
            })
        },
        methods: {
            get_engineer(en_id) {
                const [engineer] = this.engineers.filter(obj => obj.en_id == en_id);
                this.single_engineer.en_id = engineer.en_id;
                this.single_engineer.first_name = engineer.first_name;
                this.single_engineer.last_name = engineer.last_name;
                this.single_engineer.job_id = this.jobs.filter(obj => obj.job_title == engineer.job_title)[0].job_id;
                this.single_engineer.phone1 = engineer.phone1;
                this.single_engineer.phone2 = engineer.phone2;
            },
            clear_engineer() {
                this.single_engineer.en_id = null;
                this.single_engineer.first_name = "";
                this.single_engineer.last_name = "";
                this.single_engineer.job_id = null;
                this.single_engineer.phone1 = "";
                this.single_engineer.phone2 = "";
            },
            deactive_engineer(en_id) {
                this.$confirm("Are you sure deactive this engineer ?", "", "question").then(() => {
                    this.$http.patch("/engineer/deactiveEngineer/" + en_id).then(() => {
                        this.$alert("Engineer Deactived", "Success", "success");
                        const [engineer] = this.engineers.filter(obj => obj.en_id == en_id);
                        engineer.active_status = "0";
                        this.deactived_engineers.push(engineer);
                        this.engineers = this.engineers.filter(obj => obj.en_id != en_id);
                    }).catch((err) => {
                        showError(err);
                    });
                });
            },
            active_engineer(en_id) {
                this.$confirm("Are you sure active this engineer ?", "", "question").then(() => {
                    this.$http.patch("/engineer/activeEngineer/" + en_id).then(() => {
                        this.$alert("Engineer Actived", "Success", "success");
                        const [engineer] = this.deactived_engineers.filter(obj => obj.en_id == en_id);
                        engineer.active_status = "1";
                        this.engineers.push(engineer);
                        this.deactived_engineers = this.deactived_engineers.filter(obj => obj.en_id != en_id);
                    }).catch((err) => {
                        showError(err);
                    });
                });
            },
            delete_engineer() {
                if(this.single_engineer.en_id != null){
                    this.$confirm("Are you sure delete this engineer ?", "", "question").then(() => {
                        this.$http.delete("/engineer/deleteEngineer/" + this.single_engineer.en_id).then(() => {
                            this.$alert("Engineer deleted", "Success", "success");
                            this.engineers = this.engineers.filter(obj => obj.en_id != this.single_engineer.en_id);
                            this.clear_engineer();
                        }).catch((err) => {
                            showError(err);
                        });
                    });
                } else {
                    this.$alert("Select an Engineer", "", "warning");
                }
            },
            update_engineer() {
                if(this.single_engineer.en_id == null){
                    this.$alert("Select an engineer", "", "warning");
                } else if(nullValues.includes(this.single_engineer.first_name) || nullValues.includes(this.single_engineer.last_name) || nullValues.includes(this.single_engineer.job_id) || nullValues.includes(this.single_engineer.phone1)){
                    this.$alert("Fill all required fields", "Empty Field", "warning");
                } else {
                    this.$confirm("Are you sure save changes ?", "", "question").then(() => {
                        document.getElementById("btn_update_engineer").setAttribute("disabled", "disabled");
                        this.$http.patch("/engineer/updateEngineer/" + this.single_engineer.en_id,{
                            first_name: this.single_engineer.first_name,
                            last_name: this.single_engineer.last_name,
                            job_id: this.single_engineer.job_id,
                            phone1: this.single_engineer.phone1,
                            phone2: this.single_engineer.phone2
                        }).then(() => {
                            let index = this.engineers.findIndex(obj => obj.en_id == this.single_engineer.en_id);
                            this.engineers[index].first_name = this.single_engineer.first_name;
                            this.engineers[index].last_name = this.single_engineer.last_name;
                            this.engineers[index].job_id = this.single_engineer.job_id;
                            this.engineers[index].phone1 = this.single_engineer.phone1;
                            this.engineers[index].phone2 = this.single_engineer.phone2;
                            this.$alert("Successfully change saved", "Success", "success");
                        }).catch((err) => {
                            showError(err);
                        }).finally(() => {
                            document.getElementById("btn_update_engineer").removeAttribute("disabled");
                        });
                    });
                }
            }
        },
        computed: {
            filtered_engineers(){
                return this.engineers.filter(obj => {
                    return obj.en_id == this.search_engineer || 
                           obj.first_name.match(this.search_engineer) ||
                           obj.last_name.match(this.search_engineer) ||
                           obj.reg_date.match(this.search_engineer) ||
                           obj.phone1.match(this.search_engineer) ||
                           obj.phone2.match(this.search_engineer);
                });
            },
            filtered_deactived_engineers(){
                return this.deactived_engineers.filter(obj => {
                    return obj.en_id == this.search_deactive_engineer || 
                           obj.first_name.match(this.search_deactive_engineer) ||
                           obj.last_name.match(this.search_deactive_engineer) ||
                           obj.reg_date.match(this.search_deactive_engineer) ||
                           obj.phone1.match(this.search_deactive_engineer) ||
                           obj.phone2.match(this.search_deactive_engineer);
                });
            }
        },
        filters: {
            status_filter(value,language) {
                return [
                    enginnerslanguage[language].deactive,
                    enginnerslanguage[language].active
                ][value];
            },
            date_filter(value) {
                var d = new Date(value);
                return d.toISOString().split("T")[0];
            },

        }
    }
</script>
<style scoped>
 .rtl {
    text-align: right !important;
    }
</style>