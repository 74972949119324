import VueSimpleAlert from "vue-simple-alert";
import axios from "axios";
axios.defaults.withCredentials = true;

export default function(component) {
    var user_permissions = ["index" ,"attendance", "employeeReport", "engineerReport", "givedSalaryReport", "staffReport", "staffListReport", "topEmployeeReport", "foodList"];
    var engineer_permissions = ["index" ,"attendance", 'give_salary', "staffReport", "staffListReport", "salaryList", "employeeReport", "LoanAccomodation", "notifications"];
    
    axios.post("/isLogged").then(() => {
        axios.post("/getLoggedInfo").then(({data}) => {
            if((data.type == 'U' && !user_permissions.includes(component)) || (data.type == 'E' && !engineer_permissions.includes(component))){
                VueSimpleAlert.alert("You are not authorized to use this part", "Sorry", "warning").then(() => {
                    window.location.href = '/';
                });
            } else {
                document.getElementById("content-wrapper").style.display = 'block';
                document.getElementById("nav").style.display = '';
            }
        });
    }).catch(() => {
        window.location.href = '/login';
    });
}