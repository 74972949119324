<template>
    <div id="wrapper">
        <navbar id="nav" class="no-print animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn class="no-print"/>
                <div class="container-fluid text-dark">
                    <div class="row">
                        <select class="col-sm-4 form-control" v-model="st_id">
                            <option value=""></option>
                            <option v-for="staff in staffs.filter(obj => obj.special_staff == showSpecialStaffs)" :key="'s' + staff.st_id" :value="staff.st_id"> {{ staff.staff_name }} </option>
                        </select>
                        <span class="col-1">
                            <v-checkbox class="mt-0 custom-checkbox-label" v-model="showSpecialStaffs" true-value="true" false-value="false" label="Specials"></v-checkbox>
                        </span>
                        <button class="btn btn-success col-xl-2 col-md-5 mx-2 mb-2" @click="clear_expense()" data-toggle="modal" data-target="#mdl_expense" >
                            Add Expense &nbsp;
                            <i class="fa fa-plus"></i>
                        </button>
                        <a class="btn btn-primary text-white col-xl-2 col-md-5 mx-2 mb-2" href="/staff_expenses_report"> Report </a>

                        <button class="btn btn-info col-xl-2 col-md-5 mx-2 mb-2" @click="clear_project()" data-toggle="modal" data-target="#mdl_projects" >
                            Projects 
                        </button>
                    </div>
                </div>
                <hr class="my-0">
                <v-data-table class="mx-2 mt-1" :headers="headers" :items="rows">
                    <template v-slot:[`item.price`]="{item}"> {{ (item.price).toLocaleString() }} </template>
                    <template v-slot:[`item.total`]="{item}"> {{ (item.total).toLocaleString() }} </template>
                    <template v-slot:[`item.expense_date`]="{item}"> {{ new Date(item.expense_date).toISOString().split('T')[0] }} </template>
                    <template v-slot:[`item.actions`]="{item}">
                        <v-btn icon color="primary" @click="get_single_expense(item)" data-toggle="modal" data-target="#mdl_expense"> <v-icon> mdi-pencil </v-icon> </v-btn>
                        <v-btn icon color="error" @click="delete_expense(item.staff_expense_id)" > <v-icon> mdi-delete </v-icon> </v-btn>
                    </template>
                </v-data-table>
            </div>
        </div>

        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_expense">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" data-dismiss="modal" @click="clear_expense()">&times;</button>
                        <h5 class="mt-2"> Expense </h5>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row mb-3">
                                <label class="col-xl-1 mt-2 px-0"> Staff: </label>
                                <select class="form-control col-xl-11 mx-0" v-model="single_expense.st_id">
                                    <option value=""></option>
                                    <option v-for="staff in staffs.filter(obj => obj.special_staff == showSpecialStaffs)" :key="staff.st_id" :value="staff.st_id"> {{ staff.staff_name }} </option>
                                </select>
                            </div>

                            <div class="row mb-3">
                                <label class="col-xl-1 mt-2 px-0"> Date: </label>
                                <input class="form-control col-xl-11 mx-0" v-model="single_expense.expense_date" type="date">
                            </div>

                            <div class="row mb-3">
                                <label class="col-xl-1 mt-2 px-0 ms-n1"> Expense: </label>
                                <textarea class="form-control col-xl-11 ms-1 mx-0" v-model="single_expense.expense_text"> </textarea>
                            </div>
                            
                            
                            <div class="row mb-3">
                                <label class="col-xl-1 mt-2 px-0"> Quantity: </label>
                                <input class="form-control col-xl-11 mx-0" v-model="single_expense.qty" type="number">
                            </div>
                            <div class="row mb-3">
                                <label class="col-xl-1 mt-2 px-0"> Unit: </label>
                                <input class="form-control col-xl-11 mx-0" v-model="single_expense.unit" type="text">
                            </div>
                            <div class="row mb-3">
                                <label class="col-xl-1 mt-2 px-0"> Price: </label>
                                <input class="form-control col-xl-11 mx-0" v-model="single_expense.price" type="number">
                            </div>

                            <div class="row mb-3">
                                <label class="col-xl-1 mt-2 px-0"> Project: </label>
                                <select class="form-control col-xl-11 mx-0" v-model="single_expense.project_id" >
                                    <option value=""></option>
                                    <option v-for="project in projects" :key="project.project_id" :value="project.project_id"> {{ project.project_name }} </option>
                                </select>
                            </div>

                            <div class="row mb-3">
                                <label class="col-xl-1 mt-2 px-0"> Location: </label>
                                <input class="form-control col-xl-11 mx-0" v-model="single_expense.location" type="text">
                            </div>
                            
                            <div class="row mb-3">
                                <button class="btn btn-success col-xl-12 mx-0" id="btn-add" @click="add_expense()" v-if="!single_expense.staff_expense_id"> Add Expense &nbsp; <i class="fa fa-plus"></i> </button>
                                <button class="btn btn-warning col-xl-12 mx-0" id="btn-update" @click="update_expense()" v-else> Update &nbsp; <i class="fa fa-save"></i> </button>
                            </div>

                        </div>
                        
                    </div>
                </div>
            </div>
        </div>


        <div class="modal text-dark animate__animated animate__fadeInDown animate__faster" id="mdl_projects">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button class="btn btn-danger" v-on:click="clear_project()" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2 jobs"> Project </h5>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <label class="col-sm-3 mt-3"> ID: </label>
                                <label class="col-sm-9 form-control disable mt-2"> {{ single_project.project_id }} </label>
                            </div>
                            <div class="row mb-2">
                                <label class="col-sm-3 mt-2"> Project Name:  </label>
                                <input class="col-sm-9 form-control" type="text" v-model="single_project.project_name">
                            </div>
                            <hr>
                            <div class="row mb-2">
                                <div v-bind:class="{ 'col-sm-6': single_project.project_id != null, 'col-sm-12': single_project.project_id == null }"> 
                                    <button class="btn btn-success btn-block" v-on:click="add_project()"> Add &nbsp; <i class="fa fa-plus"></i> </button> 
                                </div>
                                <div class="col-sm-6" v-if="single_project.project_id != null"> 
                                    <button class="btn btn-warning btn-block" id="btn_update_project" v-on:click="update_project()"> Save Changes &nbsp; <i class="fa fa-save"></i> </button>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <input type="text" v-bind:placeholder="'Search ...'" class="form-control mb-2" v-model="project_search">
                                <table class="table table-bordered table-striped text-center text-dark">
                                    <thead>
                                        <th style="width: 6%;"> ID </th>
                                        <th class="job_title"> Project Name </th>
                                        <th style="width: 30%;"> Actions </th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="project in filtered_projects" :key="project.project_id">
                                            <td> {{ project.project_id }} </td>
                                            <td> {{ project.project_name }} </td>
                                            <td>
                                                <button class="btn btn-primary mx-1" v-on:click="get_project(project.project_id)"> <i class="fa fa-edit"></i> </button>
                                                <button class="btn btn-danger mx-1" v-on:click="delete_project(project.project_id)"> <i class="fa fa-trash"></i> </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import showError from "../showError.js";
import auth from '../auth';
const nullValues = ["", null];

export default {
    name: "staff_expenses",
    data() {
        return {
            st_id: null,
            single_expense: {
                staff_expense_id: null,
                expense_text: null,
                st_id: null,
                qty: 0,
                unit: null,
                price: 0,
                user: "",
                expense_date: new Date().toISOString().split('T')[0],
                project_id: null,
                location: null
            },
            single_project: {
                project_id: null,
                project_name: ""
            },
            staffs: [],
            rows: [],
            user: {
                username: "",
                role: "",
                en_id: null
            },
            headers: [
                {
                    text: 'Form',
                    align: 'start',
                    sortable: true,
                    value: 'form'
                },
                {
                    text: 'Expense',
                    align: 'start',
                    sortable: false,
                    value: 'expense_text'
                },
                {
                    text: 'Staff',
                    align: 'start',
                    sortable: false,
                    value: 'staff_name'
                },
                {
                    text: 'Quantity',
                    align: 'start',
                    sortable: false,
                    value: 'qty'
                },
                {
                    text: 'Unit',
                    align: 'start',
                    sortable: false,
                    value: 'unit'
                },
                {
                    text: 'Price',
                    align: 'start',
                    sortable: false,
                    value: 'price'
                },
                {
                    text: 'Total',
                    align: 'start',
                    sortable: false,
                    value: 'total'
                },
                {
                    text: 'Project',
                    align: 'start',
                    sortable: false,
                    value: 'project_name'
                },
                {
                    text: 'Location',
                    align: 'start',
                    sortable: false,
                    value: 'location'
                },
                {
                    text: 'Date',
                    align: 'start',
                    sortable: true,
                    value: 'expense_date'
                },
                {
                    text: 'User',
                    align: 'start',
                    sortable: false,
                    value: 'user'
                },
                {
                    text: 'Actions',
                    align: 'center',
                    sortable: false,
                    value: 'actions'
                },
            ],
            showSpecialStaffs: "false",
            projects: [],
            project_search: ''
        }
    },
    beforeCreate(){
        auth("salaryList");
    },
    created() {
        this.$http.post('/staff/getData').then(({data})=>{
            this.staffs = data.filter(obj => obj.show_staff == '1').sort((a, b) => a.staff_sort_code - b.staff_sort_code)
        })
        this.$http.post("/getLoggedInfo").then(({data}) => {
            this.user.username = data.username;
            this.user.role = data.type;
            this.user.en_id = data.en_id || null;
        });
        this.getData()
    },
    computed: {
        filtered_projects() {
            return this.projects.filter(obj => {
                return obj.project_id == this.project_search || obj.project_name.match(this.project_search);
            });
        },
    },
    mounted() {
        setTimeout(() => {
            this.$http.post('/projects/getData').then(({data}) => {
                this.projects = data;
            });
        }, 1000)
    },
    methods: {
        clear_expense() {
            this.single_expense = {
                staff_expense_id: null,
                expense_text: null,
                st_id: null,
                qty: 0,
                unit: null,
                price: 0,
                user: "",
                expense_date: new Date().toISOString().split('T')[0],
                project_id: null,
                location: null
            }
        },
        get_single_expense(obj) {
            this.single_expense.staff_expense_id = obj.staff_expense_id;
            this.single_expense.expense_text = obj.expense_text;
            this.single_expense.st_id = obj.st_id;
            this.single_expense.project_id = obj.project_id;
            this.single_expense.location = obj.location;
            this.single_expense.qty = obj.qty;
            this.single_expense.unit = obj.unit;
            this.single_expense.price = obj.price;
            this.single_expense.expense_date = new Date(obj.expense_date).toISOString().split('T')[0];
        },
        getData() {
            if(this.st_id) {
                this.$http.get('/staff_expenses/getByStaff/' + this.st_id).then(({data})=>{
                    this.rows = data;
                })
            } else {
                this.$http.get('/staff_expenses/all').then(({data})=>{
                    this.rows = data;
                })
            }
        },
        add_expense() {
            this.single_expense.user = this.user.username;
            document.getElementById('btn-add').setAttribute('disabled', 'disabled');
            this.$http.post('/staff_expenses/create', this.single_expense).then(({data})=>{
                if(this.single_expense.st_id == this.st_id || this.st_id == null) {
                    this.rows.unshift(data);
                }
                this.$set(this.single_expense, 'qty', 0);
                this.$set(this.single_expense, 'price', 0);
                this.$set(this.single_expense, 'unit', null);
                this.$set(this.single_expense, 'location', null);
            }).finally(() => {
                document.getElementById('btn-add').removeAttribute('disabled');
            })
        },
        update_expense() {
            this.single_expense.user = this.user.username;
            document.getElementById('btn-update').setAttribute('disabled', 'disabled');
            this.$http.patch('/staff_expenses/update/' + this.single_expense.staff_expense_id, this.single_expense).then(()=>{
                this.$alert("Updated Successfully", "Success", "success");
                let index = this.rows.findIndex(obj => obj.staff_expense_id == this.single_expense.staff_expense_id);
                this.rows[index].st_id = this.single_expense.st_id;
                this.rows[index].expense_text = this.single_expense.expense_text;
                this.rows[index].qty = this.single_expense.qty;
                this.rows[index].unit = this.single_expense.unit;
                this.rows[index].price = this.single_expense.price;
                this.rows[index].expense_date = new Date(this.single_expense.expense_date).toISOString().split('T')[0];
                this.rows[index].user = this.single_expense.user;
                this.rows[index].total = this.single_expense.qty * this.single_expense.price;
            }).finally(() => {
                document.getElementById('btn-update').removeAttribute('disabled');
            })
        },
        delete_expense(staff_expense_id) {
            this.$confirm("Are you sure you want to delete this expense?", "Delete", "question").then(() => {
                this.$http.delete('/staff_expenses/delete/' + staff_expense_id).then(()=>{
                    this.$alert("Deleted Successfully", "Success", "success");
                    this.rows = this.rows.filter(obj => obj.staff_expense_id != staff_expense_id);
                })
            })
        },
        add_project() {
            if(!nullValues.includes(this.single_project.project_name)){
                this.$http.post("/projects/addProject", {
                    project_name: this.single_project.project_name
                }).then(({data}) => {
                    this.projects.push({
                        project_id: data.project_id,
                        project_name: this.single_project.project_name
                    });
                    this.clear_project();
                }).catch((err) => {
                    showError(err);
                });
            } else {
                this.$alert("Enter a project name", "", "warning");
            }
        },
        clear_project() {
            this.single_project.project_id = null;
            this.single_project.project_name = "";
        },
        get_project(project_id) {
            const [project] = this.projects.filter(obj => obj.project_id == project_id);
            this.single_project.project_id = project.project_id;
            this.single_project.project_name = project.project_name;
        },
        delete_project(project_id) {
            this.$confirm("Are you sure delete this project ?", "", "question").then(() => {
                this.$http.delete("/projects/deleteProject/" + project_id).then(() => {
                    this.projects = this.projects.filter(obj => obj.project_id != project_id)
                    this.clear_project()
                }).catch((err) => {
                    showError(err)
                });
            });
        },
        update_project() {
            if(this.single_project.project_id == null){
                this.$alert("Select a project", "", "warning");
            } else if(nullValues.includes(this.single_project.project_name)){
                this.$alert("Enter a project name", "", "warning");
            } else {
                this.$confirm("Are you sure to save changes ?", "", "question").then(() => {
                    document.getElementById("btn_update_project").setAttribute("disabled", "disabled");
                    this.$http.post("/projects/updateProject", {
                        project_id: this.single_project.project_id,
                        project_name: this.single_project.project_name
                    }).then(() => {
                        let index = this.projects.findIndex(obj => obj.project_id == this.single_project.project_id);
                        this.projects[index].project_name = this.single_project.project_name;
                        this.$alert("", "Success", "success");
                        this.clear_project()
                    }).catch((err) => {
                        showError(err);
                    }).finally(() => {
                        document.getElementById("btn_update_project").removeAttribute("disabled");
                    });
                });
            }
        },
    },
    watch: {
        st_id() {
            this.getData();
        }
    }
}
</script>