<template>
    <div id="wrapper">
        <navbar id="nav" class="animate__animated animate__fadeInLeft no-print"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn/>
                <div class="container-fluid text-dark">
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                        <h3 class="text-dark "> {{ givedSalaryLanguage[language].gived_employee_list }} {{ month + '/' + year }}  </h3>
                    </div>
                    <hr>
                    <div class="row" style="overflow-x: auto;">
                        <label class="col-sm-1 mt-1 no-print"> {{ givedSalaryLanguage[language].month }} : </label>
                        <select class="form-control col-sm-1 no-print" v-model="month">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>

                        <label class="col-sm-1 mt-1 no-print"> {{ givedSalaryLanguage[language].year }} : </label>
                        <input type="number" class="form-control col-sm-1 no-print" v-model="year">

                        <button class="btn btn-success mx-3 no-print" v-on:click="search()"> {{ givedSalaryLanguage[language].search }} <i class="fa fa-search"></i> </button>
                        <select class="form-control col-sm-2 float-left no-print" v-model="search_staff"> 
                            <option value=""></option>
                            <option v-for="staff in staffs" :key="staff.st_id" v-bind:value="staff.staff_name"> {{ staff.staff_name }} </option>
                        </select>
                        <div class="col-xl-12 col-md-12 col-sm-12" v-for="staff in filtered_staffs" :key="staff.st_id">
                            <label class="w-100 multi-language my-3"> {{ givedSalaryLanguage[language].staff }} : {{ staff.staff_name }} </label>
                            <table class="table table-bordered table-striped text-dark text-center mt-2">
                                <thead> 
                                    <th style="width: 7%"> {{ givedSalaryLanguage[language].id }} </th>
                                    <th style="width: 25%"> {{ givedSalaryLanguage[language].name }} </th>
                                    <th style="width: 15%"> {{ givedSalaryLanguage[language].total_fine }} </th>
                                    <th style="width: 18%"> {{ givedSalaryLanguage[language].total_overtime }} </th>
                                    <th style="width: 18%"> {{ givedSalaryLanguage[language].gived_amount }} </th>
                                </thead>
                                <tbody>
                                    <tr v-for="employee in employees.filter(obj => obj.staff_name == staff.staff_name)" :key="employee.emp_id">
                                        <td> {{ employee.emp_id }} </td> 
                                        <td> {{ employee.full_name }} </td>
                                        <td>
                                            {{ employee.total_fine | fine_filter }} 
                                            <span> IQD </span>
                                        </td>
                                        <td> 
                                            {{ employee.total_overtime | money_filter }} 
                                            <span v-if="employee.salary_type == 'Monthly'"> $ </span>
                                            <span v-else> IQD </span>
                                        </td>
                                        <td> 
                                            {{ employee.gived_salary | money_filter }} 
                                            <span v-if="employee.salary_type == 'Monthly'"> $ </span>
                                            <span v-else> IQD </span>
                                        </td>
                                    </tr>
                                </tbody>
                                <caption class="alert alert-primary text-center"> 
                                    {{ givedSalaryLanguage[language].total_dollar }} : 
                                    {{ (gived_salary_staffs.filter(obj => obj.staff_name == staff.staff_name)[0] || { total_gived_dollar: 0 }).total_gived_dollar | money_filter }}
                                    $
                                    <hr>
                                    {{ givedSalaryLanguage[language].total_dinar }} : 
                                    {{ (gived_salary_staffs.filter(obj => obj.staff_name == staff.staff_name)[0] || { total_gived_dinar: 0 }).total_gived_dinar | money_filter }}
                                    IQD
                                </caption>
                            </table>
                            <hr>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import auth from '../../auth';
    import $ from 'jquery';
    import { Bus } from '../../Bus.js';
    import givedSalaryLanguage from '../../languages/gived_salary_report.js';
    export default {
        name: "givedSalaryReport",
        data() {
            return {
                month: new Date().getMonth(),
                year: new Date().getFullYear(),
                employees: [],
                staffs: [],
                gived_salary_staffs: [],
                search_staff: "",
                givedSalaryLanguage,
                language: "English"
            }
        },
        beforeCreate() {
            auth("givedSalaryReport");
        },
        created() {
            this.$http.post("/staff/getData").then(({data}) => {
                this.staffs = data;
            });
        },
        mounted() {
            this.search();

            Bus.$on("languageChanged", (data) => {
                this.language = data;
                if(["Kurdish", "Arabic"].includes(data)){
                    $(".multi-language").addClass("rtl");
                    $("#wrapper").attr("dir", "rtl")
                } else {
                    $(".multi-language").removeClass("rtl");
                    $("#wrapper").attr("dir", "ltr")
                }
            });
        },
        methods: {
            search() {
                this.$http.post("/gived_salary/getGivedSalary/" + this.month + "/" + this.year).then(({data}) => {
                    this.employees = data.employees;
                    this.gived_salary_staffs = data.gived_salary_staffs;
                });
            }
        },
        computed: {
            filtered_staffs() {
                if(this.search_staff == ""){
                    return this.staffs;
                }
                return this.staffs.filter(obj => obj.staff_name == this.search_staff);
            }
        },
        filters: {
            money_filter(value) {
                var num = [];
                var point = "";
                value = (value == null ? "" : value.toString());
                if(value.includes('.')){
                    point = value.substring(value.indexOf('.'));
                    num = value.substring(0, value.indexOf('.')).split("").reverse();
                } else {
                    num = value.split("").reverse();
                }
                var arr = [];
                for (let i = 0; i < num.length; i++) {
                    arr.unshift(num[i]);
                    if((i + 1) % 3 == 0){
                        arr.unshift(",");
                    }
                }
                if(point == ".000"){
                    point = "";
                }
                var num_with_comma = arr.join("") + point;
                if(num_with_comma.charAt(0) == ','){
                    return num_with_comma.slice(1);
                }
                return num_with_comma;
            },
            fine_filter(value) {
                if(value == "0.00"){
                    return 0;
                }
                return value;
            }
        },
        watch: {
            month() {
                this.search();
            },
            year() {
                this.search();
            }
        }
    }
</script>

<style scoped>
    td {
        padding: .5rem;
        direction: ltr;
    }
    .rtl {
        text-align: right !important;
    }
    @media print {
        .no-print {
            display: none !important;
        }
    }
</style>